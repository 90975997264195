import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, Link, useNavigate } from "react-router-dom";

import footerLogo from "../../assets/images/footer-logo.svg";
import { purchaseData } from "../Common/common";
import facebookIcon from "../../assets/images/facebook_icon.svg";
import linkedinIcon from "../../assets/images/linkedIN_icon.svg";
import twitterIcon from "../../assets/images/twitter_icon.svg";
import instagramIcon from "../../assets/images/instagram_icon.svg";
import youtube from "../../assets/images/youtube_icon.svg";
import tiktok from "../../assets/images/tiktok_icon.svg";
import samplePDF1 from "../../assets/Burial_Purchase_Agreement_pdf-p5-sample.pdf";
import samplePDF2 from "../../assets/Manifesto_for_Passing-sample-wm.pdf";
import samplePDF3 from "../../assets/Signed Deed of Grant of Exclusive Right of Burial-SAMPLE.pdf";
import samplePDF5 from "../../assets/-Burial Ground Regulations.pdf";
import ccimCertificate from "../../assets/ICCM_Membership_Certificate.pdf";
import samplePDF6 from "../../assets/-Transfer of Exclusive.pdf";
import angleIcon from "../../../src/assets/images/Angle_downIcon.svg";
import Tick from "../../../src/assets/images/Tick.png";
import Modal from "react-modal";
import { Document, Page } from "react-pdf";
import PdfView from "./pdfView";
import { setSelectedPdf } from "../../reducers/slices/headerSlice";
import iccmLogo from "../../assets/images/ICCM.svg";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

const Footer = (props) => {
  const [openDocumentOption, setOpenDocumentOption] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    setModalIsOpen(true);
  };

  const date = new Date();
  const year = date.getFullYear();

  const [tooltipHeight, setTooltipHeight] = useState("-186px");

  useEffect(() => {
    function handleScroll() {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.scrollHeight;
      const scrollPosition =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;

      if (Math.ceil(windowHeight + scrollPosition) >= documentHeight) {
        // At the bottom of the page
        setTooltipHeight("35px");
      } else {
        setTooltipHeight("-186px");
      }
    }

    // Add event listener to track scroll position
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickDocument = () => {
    setOpenDocumentOption(!openDocumentOption);
  };

  const handlePdfClick = (pdf) => {
    setModalIsOpen(false);
    dispatch(setSelectedPdf(pdf));
    navigate("/pdf");
  };

  return (
    <>
      <footer id="footer-main">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="footer-logo-col">
                <div className="footer-logo">
                  <div className="logo-link">
                    <img src={footerLogo} alt="logo" />
                  </div>
                </div>
                <div className="company-details">
                  <ul>
                    <li> Company name: Inspired Meadows Ltd </li>
                    <li> Registered number: 07812433 </li>
                    <li>
                      {" "}
                      Address: Broadwalk House, Southernhay West,<br></br>{" "}
                      Exeter, EX1 1TS{" "}
                    </li>
                    <li>
                      {" "}
                      Tel: 01392 576302 <br></br>Email:
                      info@inspiredmeadows.co.uk
                    </li>
                  </ul>
                </div>
                <div className="iccm-logo" style={{ cursor: "pointer" }}>
                  <span
                    className=""
                    onClick={() => handlePdfClick(ccimCertificate)}
                  >
                    <img src={iccmLogo} alt="" />
                    ICCM Certified
                  </span>
                </div>
                <div className="social-links">
                  <ul>
                    <li>
                      <a
                        href="https://twitter.com/MeadowsInspired"
                        target="_blank"
                      >
                        <img src={twitterIcon} alt="icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/inspired-meadows/"
                        target="_blank"
                      >
                        <img src={linkedinIcon} alt="icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="youtube-icon"
                        href="https://www.youtube.com/channel/UCC5WvQ2cc0uZ-J4iKTCv3AQ"
                        target="_blank"
                      >
                        <img src={youtube} alt="icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/InspiredMeadows"
                        target="_blank"
                      >
                        <img src={facebookIcon} alt="icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/inspired_meadows_uk/"
                        target="_blank"
                      >
                        <img src={instagramIcon} alt="icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/@inspired.meadows?lang=en"
                        target="_blank"
                      >
                        <img src={tiktok} alt="icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-links-col">
                <div className="footer-links">
                  <h2>Shop</h2>
                  {purchaseData?.map((item, index) =>
                    index === 0 ? (
                      ""
                    ) : (
                      <ul>
                        <li>
                          <Link
                            to={`/products/${
                              index == 1
                                ? "urn-plots"
                                : index == 2
                                ? "flowers"
                                : index == 3
                                ? "trees"
                                : ""
                            }`}
                          >
                            {item?.title}
                          </Link>
                        </li>
                      </ul>
                    )
                  )}
                </div>
              </div>
              <div className="footer-links-col">
                <div className="footer-links">
                  <h2>Important Links</h2>
                  <ul>
                    <li>
                      <span
                        className="document-btn"
                        onClick={handleClickDocument}
                        style={{ cursor: "pointer" }}
                      >
                        Document Library{" "}
                        <img src={angleIcon} alt="angle icon" />
                      </span>
                      <div
                        className="documents-dropdown"
                        style={{ top: tooltipHeight }}
                      >
                        <ul>
                          <li>
                            <span
                              onClick={() => handlePdfClick(samplePDF1)}
                              style={{ color: "white", cursor: "pointer" }}
                            >
                              Right of Burial Purchase Agreement
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => handlePdfClick(samplePDF2)}
                              style={{ color: "white", cursor: "pointer" }}
                            >
                              Manifesto Form
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => handlePdfClick(samplePDF3)}
                              style={{ color: "white", cursor: "pointer" }}
                            >
                              Deed of Grant of Exclusive Right of Burial
                            </span>
                          </li>

                          <li>
                            <Link
                              to="/interment-form"
                              style={{ cursor: "pointer" }}
                            >
                              Notice of Interment Form{" "}
                            </Link>
                          </li>
                          <li
                            class="lh"
                            onClick={handleClick}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            Transfer of Deed of Grant of Exclusive Right of
                            Burial
                          </li>
                          <li>
                            <span
                              onClick={() => handlePdfClick(samplePDF5)}
                              style={{ color: "white", cursor: "pointer" }}
                            >
                              Ground Regulations
                            </span>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/schedule-a-call">Schedule a Call</Link>
                    </li>
                    <li>
                      <Link to="/locations">Locations</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-links-col footer-contact-col">
                <div className="footer-links footer-help">
                  <h2>Help</h2>
                  <ul>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/faqs">FAQ’s</Link>
                    </li>
                    <li>
                      <Link to="/blogs">Latest News</Link>
                    </li>
                    <li>
                      <Link style={{whiteSpace:"nowrap"}} to="/legacy-book">Legacy Book</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-content">
              <div className="footer-copyright">
                <p>&copy; {year} inspired Meadows. All Rights Reserved.</p>
              </div>
              <div className="footer-term">
                <Link to={"/terms&condition"}>
                  <span>Terms of Use</span>
                </Link>
                <span className="circle"></span>
                <Link to={"/privacy-policy"}>
                  {" "}
                  <span>Privacy Policy</span>
                </Link>
                <span className="circle"></span>

                <Link to={"/CookiePolicy"}>
                  <span>Cookies Policy</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {modalIsOpen && (
          <>
            <div
              style={{ position: "fixed" }}
              className={`overlay ${modalIsOpen ? "logout-active" : ""}`}
            />
            <div class="info-main">
              <img src={Tick} alt="success icon" />
              <span>Info</span>
              <p>
                If you wish to proceed with a transfer, please contact the
                office.{" "}
              </p>
              <div onClick={() => handlePdfClick(samplePDF6)} class="ok-btn">
                Ok
              </div>
            </div>
          </>
        )}
      </footer>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
