import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import bannerimg from "../../assets/images/Contact_bg_img.png";
import Email_contact_icon from "../../assets/images/Email_contact_icon.png";
import Phone_contact_icon from "../../assets/images/Phone_contact_icon.png";
import Address_icon from "../../assets/images/Address_icon.png";
import Whatsup_icon from "../../assets/images/whatsUp_icon.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import contactFamily from "../../assets/images/family_img.png";
import CustomInput from "../Common/CustomInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import agent from "../../agent";
import Loader from "../components/Loader";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import NewCta from "../Home/NewCta";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

const MainView = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    phone: "",
  });

  const [loading, setIsLoading] = useState(false);
  // const thanksAndRegards = 'Thanks and Regards';
  const { name, email, phone, subject, message } = formData;

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const validatePhone = (phone) => {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phone);
  };
  // const mailtoLink = `mailto:info@inspiredmeadows.co.uk?subject=${encodeURIComponent(
  //     subject
  // )}&body=${encodeURIComponent(
  //     `${message}\n\nName: ${name}\nEmail: ${email}\nPhone: ${phone}\n\n${thanksAndRegards}`
  // )}`;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length <= 12) {
        setFormData({
          ...formData,
          [name]: numericValue,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    if (name === "email") {
      if (!validateEmail(value)) {
        setErrors({
          ...errors,
          email: "Invalid email format",
        });
      } else {
        setErrors({
          ...errors,
          email: "",
        });
      }
    }
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      setErrors({
        ...errors,
        email: "Invalid email format",
      });
      return;
    }
    if (!validatePhone(formData.phone)) {
      setErrors({
        ...errors,
        phone: "Invalid phone number format",
      });
    }
    const data = {
      email: formData?.email,
      message: formData?.message,
      name: formData?.name,
      phoneNumber: {
        dialCode: "+44",
        number: formData?.phone,
      },
      subject: formData?.subject,
    };
    try {
      setIsLoading(true);
      const response = await agent.common.sendContactMail(data);
      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.statusCode === 400) {
        const errorResponse = JSON.parse(error.response.text);
        const errorMessage = errorResponse.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  return (
    <Fragment>
      <section id="banner-img-main">
        <div className="custom-banner-img">
          <img src={bannerimg} alt="banner-img" />
        </div>
        <div className="custom-banner-text">
          <div className="container">
            <h1>Contact Us</h1>
          </div>
        </div>
      </section>
      <ToastContainer />
      <section id="contact-main">
        <div className="container">
          <div className="row">
            <div className="contact-details-col">
              <div className="contct-details">
                <div className="contact-head">
                  <h2>Let’s connect with us!</h2>
                  <p>
                    Please contact us for a no obligation call if you would like
                    more information, or just a friendly chat…... Choose your
                    preferred method of contact and one of our Advisors will be
                    in touch.
                  </p>
                  <Link to="/schedule-a-call">
                    Schedule a Call <img src={anglerighticon} alt="" />
                  </Link>
                </div>
                <div className="contact-social">
                  <ul>
                    <li>
                      <span>
                        <img src={Address_icon} alt="" />
                      </span>
                      <div className="social-left">
                        <label>Kendal Office Address:</label>
                        <a
                          href="https://www.google.com/maps/search/Unit+1,+Suite+1,+Riverside+Business+Park,+Off+Natland+Road+,+Kendal,%C2%A0LA9%C2%A07SX/@54.3134364,-2.7447722,17z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI1MDEyOC4wIKXMDSoASAFQAw%3D%3D"
                          target="_blank"
                        >
                          <p>
                            Unit 1, Suite 1, Riverside Business Park, Off
                            Natland Road , Kendal, LA9 7SX
                          </p>
                        </a>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>
                        <img src={Email_contact_icon} alt="" />
                      </span>
                      <div className="social-left">
                        <label>Email:</label>
                        <a href="mailto:info@inspiredmeadows.co.uk">
                          info@inspiredmeadows.co.uk
                        </a>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>
                        <img src={Phone_contact_icon} alt="" />
                      </span>
                      <div className="social-left">
                        <label>Kendal Office Number: </label>
                        <a href="tel:01539312442">01539 312442</a>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>
                        <img src={Whatsup_icon} alt="" />
                      </span>
                      <div className="social-left">
                        <label>What’s app: </label>
                        <a
                          href="https://wa.me/+447761064750"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          07761 064 750
                        </a>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>
                        <img src={Phone_contact_icon} alt="" />
                      </span>
                      <div className="social-left">
                        <label>Freephone:</label>
                        <a href="tel:08000293871">0800 029 3871</a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="kendal-link">
                  <p>Visit our Kendal Site: <Link target="blank" to="https://w3w.co/digress.flexed.ambushes">
                      Tap here
                    </Link></p>
                </div>
              </div>
            </div>
            <div className="contact-form-col">
              <div className="contact-form">
                <form>
                  <h3>How can we help you?</h3>
                  <div className="input-group">
                    <CustomInput
                      type="text"
                      name="name"
                      label="Name"
                      value={name}
                      required={true}
                      handleChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group">
                    <CustomInput
                      type="email"
                      name="email"
                      label="Email address"
                      value={email}
                      required={true}
                      handleChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group">
                    <CustomInput
                      type="text"
                      name="phone"
                      label="Phone number"
                      value={phone}
                      required={true}
                      handleChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group">
                    <CustomInput
                      type="text"
                      name="subject"
                      label="Subject"
                      value={subject}
                      required={true}
                      handleChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group">
                    <CustomInput
                      type="text"
                      name="message"
                      label="Message"
                      value={message}
                      required={true}
                      handleChange={handleInputChange}
                    ></CustomInput>
                  </div>
                  <div className="input-group">
                    <button onClick={handleSendEmail} disabled={loading}>
                      {" "}
                      {loading ? <Spinner /> : "Send Message"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="cta_new">
        <NewCta />
      </div> */}

      <section id="contact-main" className="family-contact-main">
        <div className="container">
          <div className="row">
            <div className="family-img-col">
              <div className="family-contact-img">
                <img src={contactFamily} />
              </div>
            </div>
            <div className="contact-details-col">
              <div className="contct-details">
                <div className="contact-social">
                  <ul>
                    <li>
                      <span>
                        <img src={Address_icon} alt="" />
                      </span>
                      <div className="social-left">
                        <label>Registered Business Address: </label>
                        <a
                          href="https://www.google.com/maps/place/Broadwalk+House,+Southernhay+W,+Exeter+EX1+1UA,+UK/@50.7242527,-3.531687,17z/data=!3m1!4b1!4m10!1m2!2m1!1sInspired+Meadows+Ltd+++++++++++++++++++++++++++++++++++++++++++++++++Broadwalk+House+Southernhay+West+Exeter+EX1+1TS!3m6!1s0x486da43dd3d22079:0xca9785c9fb92fdff!8m2!3d50.7242528!4d-3.5268161!15sCnRJbnNwaXJlZCBNZWFkb3dzIEx0ZCAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICBCcm9hZHdhbGsgSG91c2UgU291dGhlcm5oYXkgV2VzdCBFeGV0ZXIgRVgxIDFUU5IBEWNvbXBvdW5kX2J1aWxkaW5n4AEA!16s%2Fg%2F1tx8smyl?entry=ttu&g_ep=EgoyMDI1MDEyOC4wIKXMDSoASAFQAw%3D%3D"
                          target="_blank"
                        >
                          <p>
                            Inspired Meadows Ltd Broadwalk House Southernhay
                            West Exeter EX1 1TS
                          </p>
                        </a>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>
                        <img src={Phone_contact_icon} alt="" />
                      </span>
                      <div className="social-left">
                        <label>Exeter Office Number: </label>
                        <a href="tel:01392 576302">01392 576302</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
