import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import bnrImage from "../../assets/images/compress/bg-img-c.png";
import cookies from "../../assets/images/cookie_img.png";
import weDoImg from "../../assets/images/home_what.png";
import weDoTwo from "../../assets/images/home_what_two.png";
import tributeImg from "../../assets/images/tribute_img.png";
import Jungle from "../../assets/images/jungle.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import familytypes from "../../assets/images/family_types_img.png";
import familyburialImg from "../../assets/images/family_burial.png";
import GreenImg from "../../assets/images/green_img.png";
import plansImg from "../../assets/images/plans_img.png";
import memorialImg from "../../assets/images/memorial_img.png";
import NatureOne from "../../assets/images/Nature_first.jpg";
import NatureTwo from "../../assets/images/Nature_second.jpg";
import NatureThree from "../../assets/images/Nature_third.jpg";
import NatureFour from "../../assets/images/Nature_four.jpg";
import NatureFive from "../../assets/images/Nature_five.jpg";
import NatureSix from "../../assets/images/Nature_six.jpg";
import futurePlan from "../../assets/images/future_planning.jpg";
import KeepSmile from "../../assets/images/Keep_smile.jpg";
import Flowers from "../../assets/images/flowers.jpg";
import NewImg from "../../assets/images/img_two.png";
import kendalTwo from "../../assets/images/kendaltwo.png";
import Forest from "../../assets/images/forest.png";
import Couples from "../../assets/images/couples.png";
import Legacy from "../../assets/images/legacy.png";
import Story from "../../assets/images/story.png";
import CreditOne from "../../assets/images/credit_first.jpg";
import CreditTwo from "../../assets/images/credit_two.jpg";

import {
  setResetComplete,
  setResetPass,
  setShowForgot,
  setShowLogin,
  setShowRegister,
  setVerifyOtp,
} from "../../reducers/slices/headerSlice";
import Visiter from "../Common/Visiter";
import GiftPopup from "../Common/GiftPopup";
import TeamSection from "./TeamSection";
import Overview from "./Overview";
import WhyInspireMeadows from "./WhyInspireMeadows";
import Advisor from "./Advisor";
import Pets from "./Pets";
import Purchase from "./Purchase";
import BannerComponent from "../Common/Optemize/BannerComponent";
import NewCta from "./NewCta";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

const MainView = (props) => {
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [showCookies, setShowCookies] = useState(false);
  const [visiterTab, setVisiterTab] = useState(false);
  const [giftPopupToggle, setGiftPopupToggle] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();
  const handleShowLogin = () => {
    dispatch(setShowLogin(true));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(false));
    dispatch(setResetComplete(false));
  };

  useEffect(() => {
    const cookieValue = localStorage.getItem("cookies");
    const visiterGift = localStorage.getItem("visiterGift");
    if (!visiterGift && cookieValue) {
      setVisiterTab(true);
    }
    if (!cookieValue) {
      // handleShowLogin()
      setShowCookies(true);
      // localStorage.setItem("cookies",true);
    }
  }, []);

  const handleClose = () => {
    setShowCookies(false);
    setVisiterTab(true);
    localStorage.setItem("cookies", false);
  };
  const handleAccept = () => {
    setShowCookies(false);
    setVisiterTab(true);
    localStorage.setItem("cookies", true);
  };

  const togglePlayPause = () => {
    setShowImage(false);
    setIsPlaying(true);
    const video = videoRef.current;
    if (!video) {
      return;
    }

    if (video.paused) {
      video
        .play()
        .then(() => {
          setShowImage(false);
          setIsPlaying(true);
          video.controls = true;
        })
        .catch((error) => {
          console.error("Video playback error:", error);
          video.controls = false;
        });
    } else {
      video.pause();
      setIsPlaying(false);
      video.controls = false;
    }
  };

  // const handleVideoEnded = () => {
  //   setIsPlaying(false);
  //   setShowImage(true);
  // };

  const crossHandle = () => {
    localStorage.setItem("visiterGift", true);
    setVisiterTab(false);
    setGiftPopupToggle(false);
  };

  const showGiftPopup = () => {
    setGiftPopupToggle(true);
    setVisiterTab(false);
  };

  return (
    <Fragment>
      {visiterTab && (
        <Visiter crossHandle={crossHandle} showGiftPopup={showGiftPopup} />
      )}
      {giftPopupToggle && (
        <GiftPopup
          crossHandle={crossHandle}
          setGiftPopupToggle={setGiftPopupToggle}
        />
      )}
      <section id="banner-main" className="home-banner-main">
        <BannerComponent
          showImage={showImage}
          setIsPlaying={setIsPlaying}
          setShowImage={setShowImage}
          videoRef={videoRef}
        />

        <div className="banner-text-main ">
          <div className="container">
            <div className="banner-text home-banner">
              <h1>{isPlaying ? "" : "Natural Burials at Inspired Meadows"}</h1>
              {isPlaying ? (
                ""
              ) : (
                <p>
                  Choosing the perfect, peaceful place to spend the rest of your
                  days is one of the most important things you’ll do. At
                  Inspired Meadows, our natural burials, family memorial gardens
                  and green burials offer the ideal resting spot for you, your
                  loved ones, and even your pets.
                </p>
              )}
              <div className="home-btns">
                {!isPlaying && (
                  <>
                    <div className="play-link" onClick={togglePlayPause}>
                      <i
                        className={`fa ${isPlaying ? "fa-pause" : "fa-play"}`}
                        aria-hidden="true"
                      ></i>{" "}
                      {isPlaying ? "Pause Video" : "Watch Video"}
                    </div>
                  </>
                )}
                {isPlaying ? null : (
                  <Link className="schedule-btn" to="/schedule-a-call">
                    Schedule a Call <img src={anglerighticon} />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-family-main" className="what-we-do">
        <div className="container">
          <div className="row">
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={weDoTwo} />
              </div>
            </div>
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>A Peaceful Place to Rest</h2>
                <p>
                  At Inspired Meadows, we provide sustainable end-of-life
                  planning for the eco-conscious generation. Located in the
                  beautiful Southern Lake District, our meadowland offers
                  <Link className="links" to="/natural-burials">
                    {" "}
                    natural burial{" "}
                  </Link>{" "}
                  plots for an environmentally-friendly farewell—and you can
                  reserve your space at any age, alongside space for your loved
                  ones (including, of course, your beloved pets).
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="memorial-tribute-service" className="home-natural-burial">
        <div className="container">
          <div className="row">
            <div className="tribute-head-col">
              <div className="tribute-head">
                <h2>The Inspired Meadows Promise</h2>
                <img src={Jungle} />
                <p>
                  Your final resting place should be a sanctuary—peaceful,
                  permanent, and embraced by nature. At Inspired Meadows, we
                  ensure just that. Each burial plot is protected for 999 years,
                  offering a permanent place of quiet beauty where loved ones
                  can visit, reflect, and remember for generations to come.
                </p>
                <p>
                  More than just a resting place, your plot becomes part of a
                  thriving natural landscape, preserved with care and reverence.
                  Here, your legacy is not only honored but woven gently into
                  the earth, fostering new life and lasting peace.
                </p>
                <div className="home-btns">
                  {/* <Link to="/schedule-a-call">
                    Speak to an Advisor <img src={anglerighticon} />
                  </Link> */}
                  <Link className="schedule-btn" to="/schedule-a-call">
                    Schedule a Call <img src={anglerighticon} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="burials-process-main" className="home-plans-main">
        <div className="container">
          <div className="row">
            <div className="burials-process-col">
              <div className="burials-process">
                <img src={futurePlan} />
                <div className="burials-details">
                  <h3>A Gentle Approach to Planning</h3>
                  <p>
                    A meaningful, environmentally conscious farewell should be
                    within reach for everyone. To make this possible, we offer
                    flexible payment plans, allowing you to plan with ease.
                    Choosing a natural burial is a gift—to yourself, to your
                    loved ones, and to the world you leave behind. flexible
                    payment plans should be hyper linked to the finance options
                  </p>
                </div>
              </div>
            </div>
            <div className="burials-process-col">
              <div className="burials-process bg-light">
                <img src={KeepSmile} />
                <div className="burials-details">
                  <h3>Keeping Loved Ones Together—Always</h3>
                  <p>
                    Family takes many forms, and at Inspired Meadows, we believe
                    that love should never be separated. That’s why we welcome
                    beloved pets to be laid to rest alongside their owners,
                    ensuring they remain close, always. For those wishing to
                    stay together, we also offer beautifully spacious family
                    plots—so that bonds formed in life continue in the gentle
                    embrace of nature.
                  </p>
                </div>
              </div>
            </div>
            <div className="burials-process-col">
              <div className="burials-process third">
                <img src={Flowers} />
                <div className="burials-details">
                  <h3>A Living Memorial</h3>
                  <p>
                    Here, nature tends the land with quiet grace. Instead of
                    manicured lawns and rigid headstones, native wildflowers
                    bloom and trees grow tall. This ever-changing landscape
                    becomes a tribute to life itself—a peaceful sanctuary for
                    remembrance.
                  </p>
                  <p>
                    With a simple, one-time care provision, we ensure this
                    natural beauty remains protected, flourishing for
                    generations to come with endless maintenance and protection.
                  </p>
                </div>
              </div>
            </div>
            <div className="home-plans-main-col">
              <div className="home-plans--schedule-btn">
                <Link className="schedule-btn" to="/schedule-a-call">
                  Schedule a Call <img src={anglerighticon} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about-family-main"
        className="home-about-naturals why-choose-new"
      >
        <div className="container">
          <div className="row">
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={Forest} />
              </div>
            </div>
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Why Choose a Natural Burial?</h2>
                <p>
                  Planning a final resting place may not be something we often
                  think about, but choosing a natural burial is a deeply
                  meaningful decision—one that honors both life and the beauty
                  of the earth.
                </p>
                <p>
                  A natural burial in our{" "}
                  <Link to="/memorial-burials" className="links">
                    {" "}
                    memorial garden{" "}
                  </Link>{" "}
                  offers something truly special: peace. Imagine a place nestled
                  among wildflowers and ancient trees, surrounded by nothing but
                  serenity and a sense of calm. At Inspired Meadows, we provide
                  spacious, natural burial plots—far larger than the industry
                  standard—ensuring an undisturbed, peaceful sanctuary for
                  generations to visit and reflect.
                </p>
                <Link className="speak-advisor-btn" to="/schedule-a-call">
                  Speak to an Advisor <img src={anglerighticon} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="types-family-main" className="home-types-main">
        <div className="container">
          <div className="row">
            <div className="family-types-col">
              <div className="family-types">
                <div className="family-types-box">
                  <div className="types-details">
                    <h3>A Gentle Return to Nature</h3>
                    <p>
                      A natural burial is a return to the earth in its purest
                      form. By using biodegradable materials and forgoing harsh
                      chemicals, we allow nature to take its course, letting our
                      bodies become part of the beautiful landscape—nurturing
                      the soil, enriching the land, and quietly continuing the
                      cycle of life.
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureOne} />
                  </div>
                </div>
                <div className="family-types-box">
                  <div className="family-types-img">
                    <img src={NatureTwo} />
                  </div>
                  <div className="types-details home-second-types">
                    <h3>Personalised Memorials</h3>
                    <p>
                      Natural burial invites personalisation in the most
                      beautiful way. Rather than traditional gravestones, your
                      resting place can be marked with hand-selected trees and
                      flowers (a bespoke selection chosen by you), creating a
                      living memorial that flourishes through the seasons. This
                      evolving tribute is a gentle, enduring way to honour a
                      life well-lived—one that future generations can visit,
                      remember, and cherish for centuries to come.
                    </p>
                  </div>
                </div>
                <div className="family-types-box third">
                  <div className="types-details home-third-types">
                    <h3>Preserving the Earth’s Beauty</h3>
                    <p>
                      Choosing a natural burial is a final act of care for the
                      world we leave behind. When you choose a natural burial at
                      Inspired Meadows, you're not just creating a meaningful
                      resting place for your loved one or yourself, you're
                      actually contributing to the preservation of the UK’s
                      natural landscapes.
                    </p>
                    <br></br>
                    <p>
                      You’ll leave a legacy not just of remembrance, but of
                      renewal—ensuring that life, in all its forms, continues to
                      bloom.
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureThree} />
                  </div>
                </div>
                <div className="family-types-box">
                  <div className="family-types-img">
                    <img src={NatureFour} />
                  </div>
                  <div className="types-details home-third-types">
                    <h3>Enduring Care and Commitment</h3>
                    <p>
                      Our 999-year "forever plot" promise ensures that your
                      resting place remains undisturbed and lovingly maintained
                      for centuries to come. Our team is dedicated to looking
                      after our meadows, and provides ongoing care for you or
                      your loved ones. You can rest, assured, that the landscape
                      will always be tended with the same reverence and respect
                      with which it was created.
                    </p>
                  </div>
                </div>
                <div className="family-types-box third">
                  <div className="types-details home-four-types">
                    <h3>A Meaningful Choice</h3>
                    <p>
                      A natural burial is more than a final resting place—it is
                      a reflection of a life lived with intention and a
                      beautiful place for future generations to visit. Whether
                      planning ahead for yourself or honouring a loved one,
                      Inspired Meadows offers a peaceful, heartfelt way to
                      return to nature, leaving behind a legacy of beauty, love,
                      and harmony with the earth.
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureFive} />
                  </div>
                </div>
                {/* <div className="family-types-box">
                  <div className="family-types-img">
                    <img src={NatureSix} />
                  </div>
                  <div className="types-details home-five-types">
                    <h3>A Meaningful Choice</h3>
                    <p>
                      Ultimately, choosing a natural burial is about aligning
                      your values with your final farewell, while also giving
                      future generations a place to remember you. So whether
                      you're preplanning for yourself or honouring a loved one,
                      a natural burial at Inspired Meadows offers a unique and
                      meaningful way to create a lasting legacy.
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about-family-main"
        className="home-about-naturals kendal-perfect-main"
      >
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Kendal: The Perfect Spot for Your Family’s Forever Plot</h2>
                <p>
                  At Inspired Meadows Kendal, our natural meadowland is the
                  ideal resting spot for both natural burials and memorial
                  gardens, right in the centre of the majestic Lake District.
                </p>
                <p>
                  Our stunning Kendal location offers beauty, peace, and a
                  connection to the natural outdoors. Choose the perfect spot
                  for you, your loved ones, your pets, and spend the rest of
                  your days part of the beautiful symphony of nature surrounded
                  by total peace. You’ll become a part of Kendal's rich, natural
                  landscape. With a beautiful natural setting - and constant
                  care and maintenance from our staff - your resting place will
                  remain a comforting space for your family to return to over
                  and over.
                </p>
                <Link className="speak-advisor-btn" to="/schedule-a-call">
                  Speak to an Advisor <img src={anglerighticon} />
                </Link>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={kendalTwo} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="cta_new">
        <NewCta />
      </div> */}

      <div className="credit-section">
        <div className="container">
          <div className="row">
            <div className="credit-img-col">
              <div className="credit-img">
                <img src={CreditOne} />
              </div>
            </div>
            <div className="credit-content-col">
              <div className="credit-content">
                <h2>Check Your Loan Eligibility</h2>
                <p>
                  Find out if you are approved to apply & get your estimated
                  credit limit.
                </p>
                <p>
                  <b> Funeral Safe</b>, proudly partnering with{" "}
                  <b>Inspired Meadows Ltd</b>.
                </p>
                <a
                  href="https://partners.funeralsafe.co.uk/funeral-directors/inspired-meadows-ltd/"
                  target="blank"
                >
                  Check Your Eligibility
                </a>
              </div>
            </div>
            <div className="credit-img-col">
              <div className="credit-img">
                <img src={CreditTwo} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section id="about-family-main" className="what-we-do phome-payment-plan">
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Payment Plans</h2>
                <p>
                  We believe everyone should have access to a meaningful,
                  environmental farewell, which is why we offer flexible payment
                  plans with a 25% down payment. The ecological benefits of
                  natural burial are well documented, and we don’t think that
                  doing the right thing for the planet should be a luxury.
                </p>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={Couples} />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="about-family-main" className="what-we-do">
        <div className="container">
          <div className="row">
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={Legacy} />
              </div>
            </div>
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>A Living Legacy</h2>
                <p>
                  Every natural burial at Inspired Meadows helps create
                  something bigger - protected wildlife habitats, restored
                  ecosystems, and green spaces that future generations can
                  enjoy. Your final choice becomes part of the solution, not
                  part of the problem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="about-family-main" className="home-place-main">
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Your Place in Nature’s Story</h2>
                <p>
                  Whether you’re planning ahead or making arrangements for
                  someone beloved, we are here to guide you with care, patience
                  and understanding. An Inspired Meadows natural burial is a
                  return to the earth, a legacy of love, and a source of lasting
                  peace.
                </p>
                <Link className="schedule-btn" to="/schedule-a-call">
                  Schedule a Call <img src={anglerighticon} />
                </Link>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={Story} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 
      <Overview />
      <WhyInspireMeadows />

      <Purchase />

      <Advisor />

      <Pets />
      <TeamSection /> */}
      {showCookies && (
        <div>
          <div
            style={{ position: "fixed" }}
            className={`overlay-cookie ${showCookies ? "logout-active" : ""}`}
            // className={`${showCookies ? 'logout-active' : ''}`}
          />
          <div class="cookie-main">
            <img src={cookies} alt="cookies" />
            <span>Cookies Consent</span>
            <p>
              We use cookies to enhance your browsing experience and provide
              personalized content. By clicking "Accept" you agree to our use of
              cookies.
            </p>
            <ul>
              <li>
                <span class="no-btn" onClick={handleClose}>
                  Reject
                </span>
              </li>
              <li>
                <span class="active yes-btn" onClick={handleAccept}>
                  Accept
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
