import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { redirect } from "react-router-dom";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_API_URL;
// const API_ROOT = "https://zn2j5663-5051.inc1.devtunnels.ms/";
// const API_ROOT = "https://bhthusjmgr.eu-west-1.awsapprunner.com/";

const responseBody = (res) => {
  if (res?.statusCode === 403) {
    redirect("/");
    return { error: "An error occurred" };
  } else {
    return res.body;
  }
};

const tokenPlugin = (req) => {
  let token = localStorage.getItem("token");
  if (token) {
    req.set("Authorization", `Bearer ${token}`);
  }
  req.set("ngrok-skip-browser-warning", "69420");
};

const errorPlugin = (err) => {
  if (err.response && err.response.statusCode === 403) {
    window.location.href = "/";
  } else {
    throw err;
  }
};

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorPlugin),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorPlugin),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorPlugin),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorPlugin),
  login: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).then(responseBody),
  postLogin: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .set("Authorization", `Bearer ${body.token}`)
      .then(responseBody)
      .catch(errorPlugin),
};

const Auth = {
  current: (userId) => requests.get("customer/get-user-data/" + userId),
  login: (formData) => requests.login("customer/login", formData),
  signUpVerify: (formData) => requests.post("customer/signup-user", formData),
  signUp: (formData) =>
    requests.post("customer/verify-otp-for-signup", formData),
  forgotPass: (formData) => requests.post("customer/forgot-password", formData),
  verifyOtp: (formData) => requests.post("customer/verify-otp", formData),
  resetPassword: (formData) =>
    requests.put("customer/reset-password", formData),
  getOrderDetail: (userId) => requests.get(`customer/get-order/${userId}`),
};

const Customer = {
  updateProfile: (cid, formData) =>
    requests.put("customer/update-user-data/" + cid, formData),
  updatePassword: (cid, formData) =>
    requests.put("customer/change-password/" + cid, formData),
};
const postCodeRequest = {
  getPostCodeSuggestion: (postCode) =>
    requests.get(`get-postCode-address-suggestions/${postCode}`),
  getAddress: (id) => requests.get(`get-address/${id}`),
};

const legacyBookRequest = {
  addLegacyBook: (formData) =>
    requests.post("customer/add-legacybook", formData),
  getAllLegacyBooks: (name) =>
    requests.get(`customer/get-legacybooks?search=${name}`),
  getAllLegacyBookDetails: (id) =>
    requests.get(`customer/get-legacyBook/${id}`),
  getAllLegacyBookDetails: (id) =>
    requests.get(`customer/get-legacyBook/${id}`),
  getAllComments: (booksId, userId) =>
    requests.get(
      `customer/get-comments?lagacyBookId=${booksId}&userId=${userId}`
    ),
  addComments: (data) => requests.post("customer/add-comment", data),
};

const common = {
  getAllLocations: () => requests.get("customer/locations"),
  getLocation: (lid) => requests.get("customer/get-location/" + lid),
  getAllBlogs: () => requests.get("customer/get-blogs"),
  getBlog: (bid) => requests.get("customer/get-blog/" + bid),
  getAllAgents: () => requests.get("customer/get-sales-agents"),
  getFaqDetail: () => requests.get("admin/get-faq"),
  sendContactMail: (data) => requests.post("customer/send-mail", data),
  addGift: (data) => requests.post("customer/add-lead", data),

  getMemorialFlora: (type) =>
    requests.get(`customer/get-items?itemTypes=${type}`),
  getMemorialStones: () => requests.get("customer/get-items?itemTypes=Stone"),
  getUrnsAndCaskets: (type) =>
    requests.get(`customer/get-items?itemTypes=${type}`),
  getTypesAndLocations: () => requests.get("customer/get-typesLocations"),
  getPlots: (category, type, locationId) =>
    requests.get(
      `customer/get-all-plots?category=${category}&type=${type}&locationId=${locationId}`
    ),
  addToCart: (data) => {
    return requests.post("customer/add-to-cart", data);
  },
  getCart: (userId) => {
    return requests.get(`customer/get-cart?userId=${userId}`);
  },
  getSoldPlots: () => {
    return requests.get(`customer/get-sold-plots`);
  },
  updateQuantity: (cartItemId, action) =>
    requests.put(`customer/update-quantity/${cartItemId}?action=${action}`),
  deleteCartItem: (cartItemId) =>
    requests.del(`customer/delete-cart-item/${cartItemId}`),
  addOrder: (order) => requests.post("customer/start-payment", order),
  updateStatus: (id, status) =>
    requests.put(`customer/change-status/${id}?status=${status}`),
  addIntermentForm: (data) =>
    requests.post("customer/add-interment-form", data),
  postCalendlyLinks: (data) => requests.put("customer/event-time-update", data),
  updatePaymentStatus: (orderNumber, status) =>
    requests.put(
      `customer/update-payment-status/${orderNumber}?status=${status}`
    ),
};

export default {
  Auth,
  common,
  Customer,
  postCodeRequest,
  legacyBookRequest,
  // setToken: _token => {
  //   token = _token;
  // },
};
