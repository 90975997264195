import React from "react";
import bannerimg from "../../assets/images/Natural_bg_img.png";
import Natural_first from "../../assets/images/Natural_first.png";
import Natural_second from "../../assets/images/Natural_second.png";
import natural_three from "../../assets/images/natural_three.png";
import natural_four from "../../assets/images/natural_four.png";
import keyfirst from "../../assets/images/key-first.png";
import keySecond from "../../assets/images/key-second.png";
import keyThird from "../../assets/images/key-three.png";
import keyFourth from "../../assets/images/key-four.png";
import bgimg from "../../assets/images/bg_img.png";
import Faq from "./Faq";
import { Link, useLocation } from "react-router-dom";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import kendalTwo from "../../assets/images/kendaltwo.png";
import NewCta from "../Home/NewCta";
import GreenBurialsFaq from "./Greenburialsfaq";
import CreditOne from "../../assets/images/credit_first.jpg";
import CreditTwo from "../../assets/images/credit_two.jpg";


const LazyImage = React.lazy(() => import("../Common/Optemize/LazyImage"));

const Greenburials = (props) => {
  return (
    <>
    
      <section id="banner-img-main">
        <div className="banner-overlay"></div>
        <div className="custom-banner-img">
          <div>
            <img src={bannerimg} alt="overview image" />
          </div>
          {/* <img src={bannerimg} alt="banner-img" loading="lazy" /> */}
        </div>
        <div className="custom-banner-text">
          <div className="container">
            <h1>Green Burial</h1>
            <p>
              A final resting place that gives back to the earth, nurtures life,
              and celebrates your story.
            </p>
            <Link className="schedule-btn" to="/schedule-a-call">
              Schedule a Call <img src={anglerighticon} />
            </Link>
          </div>
        </div>
      </section>

      <section
        id="about-family-main"
        className="home-about-naturals kendal-perfect-main green-eco-burials"
      >
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Green Eco Burials at Inspired Meadows</h2>
                <p>
                  A natural burial is more than a farewell; it is a return to
                  the earth, a continuation of life, and a tribute to the love
                  shared between family and friends. At Inspired Meadows, we
                  offer a serene, protected space where families can remain
                  together, embraced by the beauty of nature.
                </p>
                <Link className="speak-advisor-btn" to="/schedule-a-call">
                  Speak to our Team <img src={anglerighticon} />
                </Link>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={kendalTwo} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Burials-main" className="burials-new-main">
        <div className="container">
          <div className="row">
            <div className="Burials-head-col">
              <div className="Burials-head">
                <h2> The Beauty of a Green Burial</h2>
                <p className="p-spacing">
                  Choosing a natural burial is a gentle way to honour the beauty
                  of life while nurturing the earth. It treats life and nature
                  with ultimate respect.
                </p>
                <p>
                  Free from chemicals and non-biodegradable materials, green
                  burials allow the land to flourish, creating a peaceful
                  sanctuary that grows and evolves with time.
                </p>
              </div>
            </div>
            <div className="burials-desk">
              <div className="burials-half-col">
                <div className="burials-half">
                  <img src={natural_four} alt="" />
                  <h3>A Lasting Gift to Nature</h3>
                  <p className="p-spacing">
                    Conventional burials can be harsh on the environment, but a
                    green burial at Inspired Meadows preserves the land in its
                    purest form, ensuring a gentle and respectful journey.
                    Families can plant native trees and wildflowers, ensuring
                    that their resting place becomes a source of renewal—a
                    meadow in bloom, a woodland sanctuary, a legacy of life.
                  </p>
                  {/* <p className="pt-6">
                    At Inspired Meadows, we allow families to plant trees and
                    flowers, creating a personalised plot that supports
                    biodiversity. Imagine your final resting place bringing life
                    to a forest or a blooming meadow - what better legacy to
                    leave behind to your great-great-great-great grandchildren?
                  </p> */}
                </div>
              </div>
              <div className="burials-half-col">
                <div className="burials-half">
                  <img src={natural_four} alt="" />
                  <h3>Peace of Mind, Forever</h3>
                  <p>
                    Unlike traditional cemeteries that require costly renewals,
                    Inspired Meadows offers a "forever plot" with a 999-year
                    right of burial. Your resting place will never be disturbed,
                    ensuring that it remains a sanctuary of peace and
                    remembrance for generations to come.
                  </p>
                </div>
              </div>
              <div className="burials-half-col">
                <div className="burials">
                  <img src={Natural_first} alt="" />
                  <h3>A Place for Families to Rest Together</h3>
                  <p>
                    With generous burial plots—up to ten times larger than
                    industry standards—we make it possible for loved ones to
                    rest side by side, along with cherished pets. An eternity
                    spent with those most important to you, together surrounded
                    by the serenity of nature.
                  </p>
                  <p className="pt-6">
                    Planning ahead offers comfort, ensuring that future
                    generations will always have a place to visit, reflect, and
                    feel close to those they hold dear.
                  </p>
                </div>
              </div>
              <div className="burials-half-col">
                <div className="burials">
                  <img src={Natural_second} alt="" />
                  <h3>A Personal and Meaningful Tribute</h3>
                  <p>
                    Every resting place at Inspired Meadows is unique. Think of
                    our Memorial Gardens as canvases, on which to create a
                    beautiful, personalised tribute to a life well lived.
                  </p>
                  <p className="pt-6">
                    Instead of traditional headstones, families can personalise
                    their space with trees, flowers, and plants that reflect the
                    life and spirit of their loved one. Over time, these
                    tributes become part of the landscape, creating a living
                    memorial that continues to flourish.
                  </p>
                </div>
              </div>
              <div className="burials-col burials-full-col">
                <div className="burials">
                  <img src={natural_three} alt="" />
                  <h3>A Place of Comfort and Connection</h3>
                  <p>
                    Finding solace in nature is one of the greatest gifts of a
                    green burial. At Inspired Meadows, each plot has a unique
                    location address, so family members can easily return to
                    visit, knowing exactly where to go to reflect, remember, and
                    celebrate the lives of those they love.
                  </p>
                  <Link className="schedule-btn" to="/schedule-a-call">
                    Schedule a Call <img src={anglerighticon} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="natural-key-main" className="natural-key-newmain">
        <div className="container">
          <div className="row">
            <div className="key-head-col">
              <div className="key-head">
                <h2>How Does a Green Burial Work?</h2>
                {/* <p>
                  Natural burials offer a unique and meaningful approach to
                  honouring the life of a loved one while embracing the beauty
                  and tranquility of nature. With a focus on sustainability and
                  environmental preservation, natural burials provide numerous
                  benefits for both individuals and the planet.
                </p> */}
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keyfirst} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Step 1: Choose a Resting Place</h3>
                  <p>
                    The journey begins with selecting a burial site in our
                    beautiful, expansive meadowland. Each location offers its
                    own unique character, allowing you to choose a setting that
                    feels right for you and your loved ones.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keySecond} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Step 2: Personalise Your Space</h3>
                  <p>
                    Whether for you or a loved one, your chosen resting place
                    can be as simple or as vibrant as you wish.
                  </p>
                  <p className="pt-6">
                    Families are welcome to plant trees, flowers, or native
                    plants to create a natural, living tribute that continues to
                    grow and flourish through the seasons. Choose foliage that
                    reflects life, seasons and your family—this is your place to
                    call home.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keyThird} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Step 3: A Gentle Return to Nature</h3>
                  <p>
                    At Inspired Meadows, we specialise in natural cremation
                    burials using biodegradable urns. This ensures that remains
                    return to the earth in the most harmonious way possible,
                    without leaving a lasting footprint. Protect future
                    generations and provide a serene sanctuary fo them to visit
                    for centuries to come.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keyFourth} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Step 4: Ongoing Care and Preservation</h3>
                  <p>
                    Unlike private natural burial sites, Inspired Meadows is
                    carefully maintained for the long term. With a one-time
                    maintenance provision, we ensure that every space remains
                    beautifully preserved, without placing any burden on
                    families.
                  </p>
                  <Link className="schedule-btn" to="/schedule-a-call">
                    Schedule a Call <img src={anglerighticon} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="chain-footer-col"></div>
          </div>
        </div>
      </section>
      <section id="why-green-burials">
        <div className="container">
          <div className="row">
            <div className="why-green-head-col">
              <div className="why-green-head">
                <h2>Why Choose Inspired Meadows Green Burials?</h2>
                {/* <p>
                  At Inspired Meadows, we believe the way we say goodbye should
                  reflect how we’ve chosen to live—with care, mindfulness, and
                  purpose. Here’s what makes us the preferred choice for green
                  burials in the UK.
                </p> */}
              </div>
            </div>
            <div className="why-green-cols">
              <div className="why-green">
                <h3>A Commitment to Nature</h3>
                <p>
                  Everything we do is rooted in sustainability. From the absence
                  of harmful chemicals to the encouragement of natural growth,
                  we honour the environment while providing a meaningful,
                  eco-friendly alternative to traditional burials.
                </p>
              </div>
            </div>
            <div className="why-green-cols">
              <div className="why-green">
                <h3>Thoughtful and Accessible Planning</h3>
                <p>
                  We believe that everyone should have access to a peaceful,
                  sustainable farewell. That’s why we offer flexible payment
                  plans, with an initial 25% deposit and manageable monthly
                  options.
                </p>
              </div>
            </div>
            <div className="why-green-cols">
              <div className="why-green">
                <h3>Spacious and Serene</h3>
                <p>
                  Inspired Meadows offers some of the largest burial plots
                  available—up to 16m² for traditional burials and 8m² for ashes
                  plots—providing ample space for families to remain together,
                  surrounded by the gentle beauty of nature.
                </p>
              </div>
            </div>
            {/* <div className="why-green-cols">
              <div className="why-green">
                <h4>Largest Plot Sizes in the Industry</h4>
                <p>
                  Space matters, especially if you’re looking to be buried
                  alongside loved ones. At Inspired Meadows, our burial plots
                  (16m²) and ashes plots (8m²) are larger than the industry
                  standard, giving you and your family room to create something
                  truly special.
                </p>
              </div>
            </div> */}
          </div>
          <div className="meadows-green-burials-btn">
            <Link className="schedule-btn" to="/schedule-a-call">
              Schedule a Call <img src={anglerighticon} />
            </Link>
          </div>
        </div>
      </section>

      <GreenBurialsFaq />

      {/* <div className="cta_new green-cta">
        <NewCta />
      </div> */}

      <div className="credit-section green-cta">
        <div className="container">
          <div className="row">
            <div className="credit-img-col">
              <div className="credit-img">
                <img src={CreditOne} />
              </div>
            </div>
            <div className="credit-content-col">
              <div className="credit-content">
                <h2>Check Your Loan Eligibility</h2>
                <p>
                  Find out if you are approved to apply & get your estimated
                  credit limit.
                </p>
                <p>
                  <b> Funeral Safe</b>, proudly partnering with{" "}
                  <b>Inspired Meadows Ltd</b>.
                </p>
                <a
                  href="https://partners.funeralsafe.co.uk/funeral-directors/inspired-meadows-ltd/"
                  target="blank"
                >
                  Check Your Eligibility
                </a>
              </div>
            </div>
            <div className="credit-img-col">
              <div className="credit-img">
                <img src={CreditTwo} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="new-cta-main">
        <div className="container">
          <div className="new-cta">
            <img src={bgimg} />
            <div className="new-cta-details">
              <h3>Begin Your Journey Toward a Greener Legacy</h3>
              <p>
                Inspired Meadows is more than a burial ground—it is a place of
                peace, renewal, and connection. By choosing a natural burial,
                you are leaving behind a legacy of care, sustainability, and
                enduring love.
              </p>
              <Link className="schedule-btn" to="/schedule-a-call">
                Schedule a Call <img src={anglerighticon} />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Greenburials;
