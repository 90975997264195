import React, { useState, useEffect, Fragment, useLayoutEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink, Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import { LOGIN, LOGIN_RESET, LOGOUT } from "../../constants/actionTypes";
import agent from "../../agent";
import closeBtn from "../../assets/images/times_icon.png";
import Loader from "../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  setIsLoggedIn,
  setShowPassword,
  setRememberMe,
  setLoginErr,
} from "../../reducers/slices/headerSlice";
import { makeStyles } from "@material-ui/styles";

import TextField from "@mui/material/TextField";
import CustomInput from "../Common/CustomInput";

const useStyles = makeStyles({
  root: {
    // input label when focused
    "& label.Mui-focused": {
      color: "#219653",
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#219653",
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#219653",
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#219653",
      },
    },
  },
});

const Login = (props) => {
  const {
    showLogin,
    handleCloseLogin,
    handleShowLogin,
    handleShowRegister,
    handleShowForgot,
    email,
    setEmail,
    password,
    setPassword,
    loginErr,
    setLoginErr,
  } = props;
  const [inProgress, setInProgress] = useState(false);
  const [temporaryLoginErr, setTemporaryLoginErr] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const currentUser = useSelector((state) => state.common.currentUser);
  const loginSuccess = useSelector((state) => state.common.loginSuccess);
  const loginError = useSelector((state) => state.common.loginError);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rememberMe, showPassowrd } = useSelector((state) => state.header);

  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProgress(true);
    setEmail(userEmail);
    setPassword(userPassword);
    const values = { email: userEmail, password: userPassword };
    try {
      const response = await agent.Auth.login(values);
      localStorage.setItem("token", response?.token);
      setUserPassword("");
      setUserEmail("");
      if (rememberMe) {
        localStorage.setItem("email", userEmail);
        localStorage.setItem("password", userPassword);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
      dispatch({
        type: LOGIN,
        payload: response,
      });
      dispatch(setIsLoggedIn(true));

      toast.success("Login successful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      if (error.response && error.response.statusCode === 400) {
        dispatch({
          type: LOGIN,
          payload: JSON.parse(error.response.text),
        });
        setLoginErr(error?.response?.body?.message);
        toast.error(error?.response?.body?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (error.response && error.response.statusCode === 403) {
        dispatch({
          type: LOGOUT,
          payload: JSON.parse(error.response.text),
        });
        setIsBlocked(true);
        toast.error(error?.response?.body?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        const errorMessageTimeout = setTimeout(() => {
          setIsBlocked(false);
          window.location.href = "/";
        }, 2000);
        return () => {
          clearTimeout(errorMessageTimeout);
        };
      } else {
      }
    } finally {
      setInProgress(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleCloseLogin();
    }
  }, [currentUser]);

  function onchangeHandler(e) {
    setUserEmail(e.target.value);
  }

  return (
    <Fragment>
      <div
        style={{ position: "fixed" }}
        className={`overlay ${showLogin ? "logout-active" : ""}`}
      />
      <ToastContainer />
      <div className={`login-main ${showLogin ? "login-active" : ""}`}>
        {inProgress && <Loader />}
        <form>
          <div className="logo">
            <div className="logo-link">
              <img src={logo} alt="Logo" />
            </div>
            <span onClick={handleCloseLogin}>
              <img src={closeBtn} alt="Logo" />
            </span>
          </div>
          <div className="login-field">
            <h2>Account login</h2>
            <p>Enter your details to login</p>
            <div className="input-field">
              <CustomInput
                type={"email"}
                value={userEmail}
                label={"Your email address"}
                handleChange={onchangeHandler}
              />
            </div>
            <div className="input-field">
              <TextField
                className={classes.root}
                id="outlined-password-input"
                fullWidth
                label="Password"
                value={userPassword}
                type={showPassowrd ? "text" : "password"}
                onChange={(e) => setUserPassword(e.target.value)}
                autoComplete="current-password"
              />
              {/* <input
                                placeholder="Password"
                                type={showPassowrd ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            /> */}
              <i
                onClick={(e) => dispatch(setShowPassword(!showPassowrd))}
                className={`fa-eye-login fa-eye${showPassowrd ? "" : "-slash"}`}
                aria-hidden="true"
              ></i>
            </div>
            <div className="input-field">
              <div className="input-check">
                <input
                  type="checkbox"
                  id="customCheckbox"
                  checked={rememberMe}
                  onChange={() => dispatch(setRememberMe(!rememberMe))}
                />
                <label>Stay signed in </label>
              </div>
              <div onClick={handleShowForgot} className="forgot">
                Forgot password?
              </div>
            </div>
            <div className="login-btn">
              <div
                className="login-button btn btn-md btn-primary custom-button w-100 mb-3 orange-btn-border"
                type="button"
                disabled={inProgress}
                onClick={handleSubmit}
              >
                Login
              </div>
            </div>
            {/* <p className="error">{loginErr}</p> */}
            {isBlocked && <p className="error">You are blocked by admin.</p>}
            <div className="log-footer">
              <p>
                Not a member yet?
                <Link
                  className="active-link"
                  to="#"
                  onClick={handleShowRegister}
                >
                  Register Now
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Login;
