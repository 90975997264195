import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import agent from "../../agent";
import arrow_left from "../../assets/images/arrow_left.png";
import SEO from "../SEO/SEO";
import { FETCH_BOOK_DETAILS } from "../../constants/actionTypes";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { setShowLogin } from "../../reducers/slices/headerSlice";

const Detail = () => {
  const [showCommentBox, setShowCommentBox] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [BookData, setBookData] = useState({});
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [mainImage, setMainImage] = useState("");
  const currentUser = useSelector((state) => state.common.currentUser);
  const [addCommentLoading, setAddCommentLoading] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      try {
        const response = await agent.legacyBookRequest.getAllLegacyBookDetails(
          id
        );
        const data = response;
        setBookData(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getDetails();
  }, []);

  useEffect(() => {
    if (BookData?.gallery?.length > 0) {
      setMainImage(BookData.gallery[0]);
    }
  }, [BookData]);

  const showComment = () => {
    if (currentUser === null) {
      dispatch(setShowLogin(true));
    } else {
      setShowCommentBox(!showCommentBox);
    }
  };

  const generateInitials = (name) => {
    const nameParts = name.split(" ");
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else if (nameParts.length > 1) {
      return (
        nameParts[0].charAt(0).toUpperCase() +
        nameParts[nameParts.length - 1].charAt(0).toUpperCase()
      );
    }
    return "";
  };

  const handleImageClick = (image) => {
    setMainImage(image);
  };

  // const handleClick = () => {
  //   navigate("/legacy-book");
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    return new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const getComments = async () => {
    try {
      const response = await agent?.legacyBookRequest?.getAllComments(
        id,
        currentUser?.id
      );
      const data = response;
      setComments(data?.data);
      console.log("comments", data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      getComments();
    }
  }, [currentUser]);

  const addComments = async () => {
    if (!description) {
      toast.error("Please enter comment to submit.");
      return;
    }
    try {
      setAddCommentLoading(true);
      const dataToSend = {
        userId: currentUser?.id,
        legacyBookId: id,
        description: description,
      };

      const response = await agent?.legacyBookRequest?.addComments(dataToSend);
      const data = response;
      setAddCommentLoading(false);
      setDescription("");
      setShowCommentBox(!showCommentBox);
      getComments();
    } catch (error) {
      setAddCommentLoading(false);
    }
  };

  return (
    <Fragment>
      <SEO
        title="Natural Burials in Kendal, UK - Inspired Meadows"
        description="Explore natural burials in Kendal, UK at Inspired Meadows, offering eco-friendly burial options in serene, natural surroundings for a peaceful return to nature."
      />
      <section id="legacy-details-main">
        {loading ? (
          <div style={{ height: "75vh" }}>
            <Loader />
          </div>
        ) : (
          <div class="container">
            <button style={{ cursor: "pointer" }} class="go-back">
              <Link to="/legacy-book">
                <img src={arrow_left} alt="" />
              </Link>{" "}
              Go back
            </button>{" "}
            <h2>Legacy Book</h2>
            <div class="legacy-profile-details">
              <div class="data">
                <div class="pic-data">
                  <div class="profile-name">
                    <div class="pic">
                      <img src={mainImage} alt="Selected" />
                    </div>
                    <div class="name">
                      <h3>{BookData?.user?.name}</h3>
                      <span>
                        {formatDate(BookData?.birthDate)} -{" "}
                        {formatDate(BookData?.deathDate)}
                      </span>
                    </div>
                  </div>
                  <ul>
                    {BookData?.gallery?.map((image, index) => (
                      <li key={index}>
                        <img
                          onClick={() => setMainImage(image)}
                          src={image}
                          alt={`Gallery ${index + 1}`}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <p>{BookData?.memoryLane}</p>
              </div>
            </div>
            <div class="comments-main">
              <div class="head">
                <h3>Comments</h3>
                {/* {currentUser && ( */}
                <button onClick={showComment}>Add Comment</button>
                {/* )} */}
              </div>

              <div class="comments">
                {showCommentBox && (
                  <div class="comment-box comment-type">
                    <div class="comment-profile">
                      {currentUser?.image ? (
                        <img src={currentUser?.image} alt="" />
                      ) : (
                        <span>{generateInitials(currentUser?.firstName)}</span>
                      )}
                    </div>
                    <div class="comment-user">
                      <textarea
                        placeholder="Add your comment..."
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                      <button
                        disabled={addCommentLoading}
                        onClick={addComments}
                        class="submit-btn"
                      >
                        {addCommentLoading ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                )}
                {comments?.map((comment, index) => (
                  <div class="comment-box" key={index}>
                    <div class="comment-profile">
                      {comment?.customer?.image ? (
                        <img src={comment?.customer?.image} alt="" />
                      ) : (
                        <span>{generateInitials(comment?.customer?.name)}</span>
                      )}
                    </div>
                    <div class="comment-user">
                      <span>
                        {comment?.isSelfUserComment ? (
                          <b>{comment?.customer?.name}</b>
                        ) : (
                          comment?.customer?.name
                        )}
                      </span>
                      {comment?.isSelfUserComment ? (
                        <b>{comment?.description}</b>
                      ) : (
                        comment?.description
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </section>
    </Fragment>
  );
};

export default Detail;
