import React from "react";
import Memorialgardien from "./Memorialgardien";
import SEO from "../SEO/SEO";

const MainMemorial = (props) => {
  let mainProps = {};
  return (
    <div className="natural-burials-page">
      <SEO
        title="Eco-Friendly Memorial Gardens UK | Inspired Meadows"
        description="Inspired Meadows offers tranquil, eco-friendly memorial gardens across the UK. Honor loved ones in sustainable spaces that celebrate life and nature."
        keywords="Natural burials, Green burial sites, Memorial burial sites, Sustainable and ethical funeral practices, Biodegradable caskets"
        href="https://www.inspiredmeadows.co.uk/memorial-burials"
      />
      <Memorialgardien {...mainProps} />
    </div>
  );
};

export default MainMemorial;
