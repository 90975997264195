import React from "react";
import bgimg from "../../assets/images/bg_img.png";
import familytypes from "../../assets/images/family_types_img.png";
import bannerimg from "../../assets/images/Natural_bg_img.png";
import familyburialImg from "../../assets/images/family_burial.png";
import memorialImg from "../../assets/images/memorial_img.png";
import tributeImg from "../../assets/images/tribute_img.png";
import memorialPlots from "../../assets/images/memorial_garden.jpg";
import NatureOne from "../../assets/images/Nature_first.jpg";
import NatureThree from "../../assets/images/Nature_third.jpg";
import NatureFive from "../../assets/images/Nature_five.jpg";
import { Link, useLocation } from "react-router-dom";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import kendalTwo from "../../assets/images/kendaltwo.png";
import Natural_first from "../../assets/images/Natural_first.png";
import Natural_second from "../../assets/images/Natural_second.png";
import natural_three from "../../assets/images/natural_three.png";
import natural_four from "../../assets/images/natural_four.png";
import NewCta from "../Home/NewCta";
import CreditOne from "../../assets/images/credit_first.jpg";
import CreditTwo from "../../assets/images/credit_two.jpg";


const Memorialgardien = () => {
  return (
    <>
      <section id="banner-img-main">
        <div className="banner-overlay"></div>
        <div className="custom-banner-img">
          <div>
            <img src={bannerimg} alt="overview image" />
          </div>
          {/* <img src={bannerimg} alt="banner-img" loading="lazy" /> */}
        </div>
        <div className="custom-banner-text">
          <div className="container">
            <h1>Memorial Gardens</h1>
            <p>
              Create a serene legacy in our beautifully crafted memorial
              gardens, where your loved ones can find peace together, forever.
            </p>
            <Link className="schedule-btn" to="/schedule-a-call">
              Schedule a Call <img src={anglerighticon} />
            </Link>
          </div>
        </div>
      </section>

      <section
        id="about-family-main"
        className="home-about-naturals kendal-perfect-main green-eco-burials"
      >
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Inspired Meadows Memorial Gardens</h2>
                <p>
                  Memorial gardens offer a gentle and meaningful alternative to
                  traditional cemeteries—one that embraces the beauty of nature
                  while honouring those we cherish. Nestled within rolling
                  meadows in the heart of the Lake District, our burial grounds
                  are designed to blend seamlessly with the landscape, creating
                  an environment of serenity, renewal, and lasting connection.
                </p>
                <Link to="/schedule-a-call">
                  Speak to our Team
                  <img src={anglerighticon} />
                </Link>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={kendalTwo} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="about-family-main" className="about-memorial">
        <div className="container">
          <div className="row">
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={memorialPlots} />
              </div>
            </div>
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>About Our Memorial Gardens</h2>
                <p>
                  Memorial gardens offer a serene and sustainable alternative to
                  traditional cemeteries. At Inspired Meadows, we believe that
                  memorial spaces should honour the departed while preserving
                  our natural landscapes for future generations. Situated within
                  sprawling, untouched meadowland in the Lake District, our
                  burial grounds offer families a place of peace, reflection,
                  and permanence.  
                </p>
                <p>
                  Unlike overcrowded traditional cemeteries, our memorial
                  gardens provide not only lasting dignity but also address the
                  pressing issue of burial space scarcity in the UK. With plots
                  designed to become part of the flourishing natural
                  environment, we at Inspired Meadows offer a thoughtful and
                  lasting tribute to loved ones without succumbing to the
                  challenges faced by conventional burial practices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section
        id="Burials-main"
        className="burials-new-main about-memorial-garden"
      >
        <div className="container">
          <div className="row">
            <div className="Burials-head-col">
              <div className="Burials-head">
                <h2>About Our Memorial Gardens</h2>
              </div>
            </div>
            <div className="burials-desk">
              <div className="burials-half-col">
                <div className="burials-half">
                  <img src={natural_four} alt="" />
                  <h3>A Sanctuary of Peace and Remembrance</h3>
                  <p className="p-spacing">
                    Memorial gardens offer a serene and sustainable alternative
                    to traditional cemeteries. At Inspired Meadows, we believe
                    that memorial spaces should honour the departed while
                    preserving our natural landscapes for future generations.
                  </p>
                  <p className="pt-6">
                    Situated within sprawling meadowland in the Lake District,
                    our burial grounds offer families a place of peace,
                    reflection, and permanence. Here, remembrance takes the form
                    of flourishing wildflowers, native trees, and the soft
                    rustling of leaves in the breeze—a tribute as enduring as
                    nature itself.
                  </p>
                </div>
              </div>
              <div className="burials-half-col">
                <div className="burials-half">
                  <img src={natural_four} alt="" />
                  <h3>A Natural and Lasting Memorial</h3>
                  <p>
                    Memorial gardens at Inspired Meadows offer an eco-conscious
                    approach to burial and remembrance, where every site becomes
                    a vital part of the surrounding ecosystem. Unlike
                    traditional UK cemeteries, where space is often limited and
                    graves may be subject to reuse, our plots are preserved for
                    999 years, ensuring that your loved one’s resting place
                    remains untouched and protected for generations to come.
                  </p>
                  <p className="pt-6">
                    With burial plots that are up to ten times larger than
                    standard cemetery plots, our approach ensures a sense of
                    space, dignity, and harmony with the earth. Each site
                    becomes a living memorial, nurtured by the landscape rather
                    than confined by stone.
                  </p>
                </div>
              </div>
              <div className="burials-half-col">
                <div className="burials">
                  <img src={Natural_first} alt="" />
                  <h3>Burial and Memorial Options</h3>
                  <p>
                    We offer a range of environmentally conscious burial and
                    memorial options to suit different wishes and traditions.
                    Whether you choose a full burial or the interment of
                    cremated remains, each plot at Inspired Meadows becomes part
                    of a thriving natural sanctuary. Our natural burials ensure
                    that every plot becomes an integral part of the ecosystem,
                    promoting growth and eternal life.
                  </p>
                  <p className="pt-6">
                    Instead of traditional headstones, we encourage families to
                    mark their loved ones’ resting places with native trees,
                    wildflowers, or carefully chosen natural markers. Over time,
                    these memorials grow and evolve, creating a landscape of
                    remembrance that feels organic and everlasting.
                  </p>
                </div>
              </div>
              <div className="burials-half-col">
                <div className="burials">
                  <img src={Natural_second} alt="" />
                  <h3>Personalising Your Memorial Garden</h3>
                  <p>
                    Every life is unique, and we believe that a final resting
                    place should reflect that individuality and spirit of
                    character. Inspired Meadows offers thoughtful
                    personalisation options to create a meaningful tribute that
                    celebrates the essence of your loved one.
                  </p>
                  <p className="pt-6">
                    Families can choose to plant particular trees, flowers, or
                    shrubs that reflect their loved one’s spirit, allowing
                    nature to carry their memory forward in a way that is both
                    symbolic and enduring. This approach not only provides
                    comfort to those who visit but also contributes to the
                    vibrancy of the local environment, ensuring a lasting impact
                    that extends far beyond the present moment.
                  </p>
                </div>
              </div>
            </div>
            <div className="memorial-garden-btn">
              <Link className="schedule-btn" to="/schedule-a-call">
                Schedule a Call <img src={anglerighticon} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="about-family-main" className="memorial-option-main">
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Burial and Memorial Options</h2>
                <p>
                  We’re proud to offer a variety of environmentally considerate
                  burial and memorial options designed to meet your family’s
                  needs. Our natural burials ensure that every plot becomes an
                  integral part of the ecosystem, promoting growth and life in
                  an otherwise solemn space.  
                </p>
                <p>
                  Unlike traditional cemeteries where graves may be disturbed
                  after 75 years, we secure all plots for an extraordinary 999
                  years, giving families the assurance of a true "forever"
                  resting place. Every burial site is generously spacious—ten
                  times larger than the standard plot in a typical
                  cemetery—giving your loved one the room that represents the
                  legacy they leave behind. 
                </p>
                <p>
                  Memorial gardens at Inspired Meadows are carefully nurtured to
                  create a living tribute, replacing traditional headstones with
                  vibrant, thriving landscapes. These natural spaces provide a
                  comforting and peaceful environment where families can gather,
                  reflect, and celebrate cherished memories for years to come
                </p>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={memorialImg} />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="memorial-tribute-service">
        <div className="container">
          <div className="row">
            <div className="tribute-head-col">
              <div className="tribute-head">
                <h2>Personalisation and Tribute Services</h2>
                <img src={tributeImg} />
                <p>
                  We understand that every individual is unique, and their
                  legacy deserves the utmost care and thoughtfulness. Inspired
                  Meadows offers an array of personalisation options to ensure
                  every burial is as meaningful and distinctive as the life it
                  commemorates. 
                </p>
                <p>
                  Our tribute services include planting trees, shrubs, or
                  flowers selected by the family to create a beautiful, living
                  memorial to their loved one.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="types-family-main" className="new-type-family">
        <div className="container">
          <div className="row">
            <div className="type-family-head-col">
              <div className="type-family-head">
                <h2>Why Choose a Memorial Garden at Inspired Meadows?</h2>
                {/* <p>
                  The overcrowding crisis in UK cemeteries has raised important
                  questions about how we honour and memorialise our loved ones,
                  with some suggesting grave re-use. At Inspired Meadows, we
                  offer a forward-thinking alternative to these outdated
                  practices.
                </p> */}
              </div>
            </div>
            <div className="family-types-col">
              <div className="family-types">
                <div className="family-types-box">
                  <div className="types-details first">
                    <h3>A Place of Enduring Peace</h3>
                    <p>
                      Unlike traditional burial grounds where gravesites may
                      require renewal, Inspired Meadows guarantees a 999-year
                      right of burial, ensuring that every plot remains
                      undisturbed.
                    </p>
                    <p>
                      Families can take comfort in knowing that their loved
                      ones' resting places will always be preserved in the
                      gentle embrace of nature.
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureOne} />
                  </div>
                </div>
                <div className="family-types-box">
                  <div className="family-types-img">
                    <img src={NatureThree} />
                  </div>
                  <div className="types-details">
                    <h3>A Greener Alternative</h3>
                    <p>
                      Our approach to natural burial supports the restoration of
                      the land, replacing stone and concrete with flourishing
                      trees and flowers. By choosing a memorial garden, you are
                      not only honouring a loved one’s memory but also
                      contributing to the protection of the natural world.
                    </p>
                  </div>
                </div>
                <div className="family-types-box third">
                  <div className="types-details">
                    <h3>A Thoughtful and Modern Approach</h3>
                    <p>
                      We believe that planning for the future should be
                      accessible and free from unnecessary stress. Inspired
                      Meadows offers flexible payment options, allowing families
                      to secure a resting place that aligns with their values
                      and wishes without financial burden.
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureFive} />
                  </div>
                </div>
                <div className="family-types-box">
                  <div className="family-types-img">
                    <img src={NatureThree} />
                  </div>
                  <div className="types-details">
                    <h3>Space to Reflect and Remember</h3>
                    <p>
                      Our memorial gardens provide a peaceful sanctuary for
                      family and friends to gather, reflect, and celebrate life.
                      Each site is thoughtfully maintained to ensure a tranquil
                      and welcoming environment for all who visit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="cta_new">
        <NewCta />
      </div> */}

      <div className="credit-section">
        <div className="container">
          <div className="row">
            <div className="credit-img-col">
              <div className="credit-img">
                <img src={CreditOne} />
              </div>
            </div>
            <div className="credit-content-col">
              <div className="credit-content">
                <h2>Check Your Loan Eligibility</h2>
                <p>
                  Find out if you are approved to apply & get your estimated
                  credit limit.
                </p>
                <p>
                  <b> Funeral Safe</b>, proudly partnering with{" "}
                  <b>Inspired Meadows Ltd</b>.
                </p>
                <a
                  href="https://partners.funeralsafe.co.uk/funeral-directors/inspired-meadows-ltd/"
                  target="blank"
                >
                  Check Your Eligibility
                </a>
              </div>
            </div>
            <div className="credit-img-col">
              <div className="credit-img">
                <img src={CreditTwo} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        id="new-cta-main"
        className="memorial-cta-new memorial-new-custom"
      >
        <div className="container">
          <div className="new-cta">
            <img src={bgimg} />
            <div className="new-cta-details">
              <h2>Reserve a Living Memorial</h2>
              <p>
                Whether planning ahead or making arrangements for a loved one,
                we are here to guide you with care and compassion.
              </p>
              <p>
                Contact our team today to discover how a memorial garden at
                Inspired Meadows can offer a lasting, meaningful tribute that
                honours both your loved ones and the world they leave behind.
              </p>
              <Link className="schedule-btn" to="/schedule-a-call">
                Schedule a Call <img src={anglerighticon} />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Memorialgardien;
