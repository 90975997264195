import React, { useState } from "react";
import AccordionItem from "../FAQ/AccordionItem";

const GreenBurialsFaq = () => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const toggleAccordion = (index) => {
    if (expandedIndex === index) {
      // Clicked on an already open item, so close it.
      setExpandedIndex(null);
    } else {
      // Clicked on a new item, so open it.
      setExpandedIndex(index);
    }
  };
  const data = [
    {
      question: "What makes a green burial environmentally friendly?",
      answer:
        "Natural burials avoid the use of harmful chemicals and non-biodegradable materials. Instead, they encourage the natural return of remains to the earth, fostering biodiversity and promoting the health of the surrounding landscape.",
        answer:
        "At Inspired Meadows, our Memorial Gardens are also thriving landscapes with wildlife, trees and flowers, inspired by the people who rest there. This biodiversity is helping preserve the land and create a space in nature for generations to come.",
    },
    {
      question: "Can I arrange a family burial?",
      answer:
        "Yes. At Inspired Meadows, families can purchase adjoining plots in advance, ensuring that loved ones remain together in a place of beauty and peace. Pets are also welcome, so no family member is left behind.",
    },
    {
        question: "What is a forever plot?",
        answer:
          "A forever plot at Inspired Meadows is protected for 999 years. This means we ensure it remains untouched and well-maintained for multiple generations to come—without the need for costly renewals.",
          answer:
          "We believe a final resting place should be just that, so we provide ongoing care and protection for decades.",
      },
      {
        question: "How do I find my loved one’s resting place?",
        answer:
          "Each burial site has a unique location address, making it easy for family and friends to visit and reflect in a familiar, comforting space.",
      },
      {
        question: "Will I need to maintain the burial site?",
        answer:
          "There’s no maintenance required by you. Inspired Meadows takes care of all ongoing maintenance, ensuring that every burial site remains a place of natural beauty, free from the worries of upkeep. We take care of the garden, so you can focus on enjoying the space and remembering those cherished memories.",
      },
  ];
  return (
    <div>
      <section id="Faq-main" className="faq-new-main">
        <div className="container">
          <div className="row">
            <div className="faq-col">
              <div className="faq">
                <h2>Frequently Asked Questions</h2>
                <ul>
                  {data?.map((ques, index) => (
                    <AccordionItem
                      key={index}
                      number={index}
                      title={ques?.question}
                      content={ques?.answer}
                      isExpanded={expandedIndex === index}
                      toggleAccordion={toggleAccordion}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GreenBurialsFaq;
