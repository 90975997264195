import React from "react";
import SEO from "../SEO/SEO";
import Greenburials from "./Greenburials";

const MainGreenBurials = (props) => {
  let mainProps = {};
  return (
    <div className="natural-burials-page">
      <SEO
        title="Green Burials in the UK | Inspired Meadows"
        description="Discover eco-friendly green burials in the UK with Inspired Meadows. Explore sustainable options that honor loved ones while protecting the planet."
        keywords="Natural burials, Green burial sites, Sustainable and ethical funeral practices, Biodegradable caskets"
        href="https://www.inspiredmeadows.co.uk/green-burials"
      />
      <Greenburials {...mainProps} />
    </div>
  );
};

export default MainGreenBurials;
