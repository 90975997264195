import React, { Fragment, useEffect, useState, useRef } from "react";
import agent from "../../agent";
import Loader from "../components/Loader";
import searchImage from "../../assets/images/search_icon.svg";
import emptyLegacy from "../../assets/images/empty_memory.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const MainView = () => {
  const [books, setBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const debounceTimeout = useRef(null);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const location = useLocation();

  useEffect(() => {
    debounceTimeout.current = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 1000);

    return () => clearTimeout(debounceTimeout.current);
  }, [searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await agent.legacyBookRequest.getAllLegacyBooks(
          debouncedSearch || ""
        );
        const data = response;
        setBooks(data?.data);
      } catch (error) {
        console.error("Error fetching books:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [debouncedSearch, location.pathname]);

  const maxLength = 100;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const generateInitials = (name) => {
    const nameParts = name.split(" ");
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else if (nameParts.length > 1) {
      return (
        nameParts[0].charAt(0).toUpperCase() +
        nameParts[nameParts.length - 1].charAt(0).toUpperCase()
      );
    }
    return "";
  };

  return (
    <Fragment>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          <section id="legacy-main">
            <div class="container">
              <div class="legacy-container">
                <div class="search-legacy">
                  <h2>Legacy Book</h2>
                  <div class="search-field">
                    <input
                      type="search"
                      placeholder="Search by name"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <span>
                      <img src={searchImage} alt="" />
                    </span>
                  </div>
                </div>
                {books?.length === 0 ? (
                  <div className="empty-legacy">
                    <img src={emptyLegacy} />
                    <p>No data found</p>
                  </div>
                ) : (
                  <div class="view-legacy">
                    <h3>View Legacies</h3>
                    <div class="legacy-list">
                      {books?.map((book, index) => (
                        <div class="box" key={index}>
                          <div class="profile-img">
                            {book?.user?.image ? (
                              <img src={book?.user?.image} alt="" />
                            ) : (
                              generateInitials(book?.user?.name)
                            )}
                          </div>
                          <div class="detail">
                            <Link
                              to={`/legacy-book/${book?.id}`}
                              key={index}
                            >
                              <h4>{book?.user?.name}</h4>
                            </Link>
                            <span>
                              {formatDate(book?.birthDate)} -{" "}
                              {formatDate(book?.deathDate)}
                            </span>
                            <p>
                              {" "}
                              {book?.memoryLane &&
                              book?.memoryLane.length > maxLength
                                ? `${book?.memoryLane.substring(
                                    0,
                                    maxLength
                                  )}...`
                                : book?.memoryLane}
                            </p>
                            {book?.memoryLane &&
                              book?.memoryLane.length > maxLength && (
                                <Link
                                  to={`/legacy-book/${book?.id}`}
                                  className="read-more"
                                >
                                  Read More
                                </Link>
                              )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </Fragment>
  );
};

export default MainView;
