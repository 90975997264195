import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import MainView from "./MainView";
import SEO from "../SEO/SEO";
import useDocumentTitle from "../hooks/UseDocumentTitle";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const NaturalBurials = (props) => {
  let mainProps = {};
  useDocumentTitle("Natural Burials - Eco-Friendly Burial");
  return (
    <div className="natural-burials-page">
      <SEO
        title="Natural Burials in UK | Eco-Friendly Return to Nature - Inspired Meadows"
        description="Discover natural burials in the UK for an eco-friendly return to nature,offering sustainable, green burial options that honor both loved ones and the environment."
        keywords="Natural burials, Green burial sites, Sustainable and ethical funeral practices, Biodegradable caskets"
        href="https://www.inspiredmeadows.co.uk/natural-burials"
      />
      <MainView {...mainProps} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NaturalBurials);
