import React from "react";
import Familyburials from "./Familyburials";
import SEO from "../SEO/SEO";

const MainFamilyBurials = (props) => {
  let mainProps = {};
  return (
    <div className="natural-burials-page">
      <SEO
        title="Family Burials in the UK | Inspired Meadows"
        description="Celebrate lasting family connections with Inspired Meadows. Explore serene, sustainable family burial options across the UK to honor loved ones together."
        keywords="Natural burials, Green burial sites, Family burial sites, Sustainable and ethical funeral practices, Biodegradable caskets"
        href="https://www.inspiredmeadows.co.uk/family-burials"
      />
      <Familyburials {...mainProps} />
    </div>
  );
};

export default MainFamilyBurials;
