import React from "react";
import bannerimg from "../../assets/images/Natural_bg_img.png";
import familyburialImg from "../../assets/images/family_burial.png";
import Natural_first from "../../assets/images/Natural_first.png";
import Natural_second from "../../assets/images/Natural_second.png";
import natural_three from "../../assets/images/natural_three.png";
import familytypes from "../../assets/images/family_types_img.png";
import bgimg from "../../assets/images/bg_img.png";
import FamilyBurailsPlots from "../../assets/images/family_burials_plots.jpg";
import NatureTwo from "../../assets/images/Nature_second.jpg";
import NatureSix from "../../assets/images/Nature_six.jpg";
import NatureFour from "../../assets/images/Nature_four.jpg";
import { Link, useLocation } from "react-router-dom";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import kendalTwo from "../../assets/images/kendaltwo.png";
import NewCta from "../Home/NewCta";
import CreditOne from "../../assets/images/credit_first.jpg";
import CreditTwo from "../../assets/images/credit_two.jpg";


const Familyburials = (props) => {
  return (
    <>
      <section id="banner-img-main">
        <div className="banner-overlay"></div>
        <div className="custom-banner-img">
          <div>
            <img src={bannerimg} alt="overview image" />
          </div>
          {/* <img src={bannerimg} alt="banner-img" loading="lazy" /> */}
        </div>
        <div className="custom-banner-text">
          <div className="container">
            <h1>Family Burial Plots </h1>
            <p>
              Create a lasting legacy with our thoughtfully designed family
              burial plots, where you and your loved ones can rest together,
              forever.
            </p>
            <Link className="schedule-btn" to="/schedule-a-call">
              Schedule a Call <img src={anglerighticon} />
            </Link>
          </div>
        </div>
      </section>

      <section
        id="about-family-main"
        className="home-about-naturals kendal-perfect-main green-eco-burials"
      >
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Family Burials at Inspired Meadows</h2>
                <p>
                  Family burial plots provide a designated space where loved
                  ones can rest together, side by side, in a peaceful natural
                  setting. At Inspired Meadows, we offer thoughtfully designed
                  spaces that honour the bonds of family while nurturing the
                  earth.
                </p>
                <Link to="/schedule-a-call">
                  Learn More
                  <img src={anglerighticon} />
                </Link>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={kendalTwo} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-family-main" className="family-burials-plot">
        <div className="container">
          <div className="row">
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={FamilyBurailsPlots} />
              </div>
            </div>
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>What Are Family Burial Plots?</h2>
                <p>
                  Family burial plots provide a designated space where loved
                  ones can rest together, side by side, in a peaceful natural
                  setting. A family burial plot is a shared sanctuary where
                  generations can remain connected, embraced by the beauty of
                  nature.Unlike traditional cemeteries with single-person plots,
                  our family burial areas offer ample space to bring generations
                  together, ensuring that the connection between loved ones
                  endures.
                </p>
                <p>
                  At our Lake District meadowland, we offer flexibility and
                  care, allowing for both traditional and cremation burials, as
                  well as the option to include beloved pets. Here, your resting
                  place is not marked by stone, but by the vibrant life of
                  trees, flowers, and wild meadows—a living tribute that
                  continues to flourish.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Burials-main" className="family-burials-main">
        <div className="container">
          <div className="row">
            <div className="Burials-head-col">
              <div className="Burials-head">
                <h2>The Benefits of Choosing a Family Burial Plot</h2>
              </div>
            </div>
            <div className="burials-desk">
              <div className="burials-col">
                <div className="burials">
                  <img src={Natural_first} alt="" />
                  <h3>Rest Together, Always</h3>
                  <p>
                    Family is forever, and so is your final resting place. Our
                    family plots ensure that loved ones remain together,
                    offering a place of connection for generations to come. With
                    the option to pre-purchase, families can plan ahead with
                    peace of mind, knowing that a serene, natural space is
                    reserved just for them.
                  </p>
                </div>
              </div>
              <div className="burials-col">
                <div className="burials">
                  <img src={Natural_second} alt="" />
                  <h3>A Place for Cherished Pets</h3>
                  <p>
                    Pets are family too. Our Lake District meadow is one of the
                    few burial grounds in the UK that welcomes pets to rest
                    alongside their owners. Honouring the love shared between
                    you and your animal companions, our burial plots ensure that
                    no member of the family is left behind.
                  </p>
                </div>
              </div>
              <div className="burials-col">
                <div className="burials">
                  <img src={natural_three} alt="" />
                  <h3>Spacious, Personalised Resting Places</h3>
                  <p>
                    Our burial plots are designed with care, offering generous
                    space—16m² for traditional burials and 8m² for cremated
                    remains, far exceeding the industry standard. Over time,
                    these spaces will transform into personalised memorial
                    gardens, growing and evolving just as the memories of those
                    laid to rest within them.
                  </p>
                </div>
              </div>
              <div className="burials-col">
                <div className="burials">
                  <img src={natural_three} alt="" />
                  <h3>A Gentle Choice for the Earth</h3>
                  <p>
                    A family burial plot at Inspired Meadows is not only a
                    personal decision but an environmental one. Our green burial
                    approach avoids non-biodegradable materials and harmful
                    chemicals, fostering a space that supports local wildlife
                    and sustains the natural landscape.
                  </p>
                  <p>
                    By planting trees and flowers, families create a thriving
                    ecosystem that will continue to flourish long into the
                    future.
                  </p>
                </div>
              </div>
              <div className="burials-col">
                <div className="burials">
                  <img src={natural_three} alt="" />
                  <h3>Carefree, Forever Maintenance</h3>
                  <p>
                    We understand that families should be free to visit,
                    reflect, and find comfort, without having to worry about
                    upkeep. That’s why we offer a one-time maintenance
                    provision, ensuring that your family’s space remains
                    lovingly cared for, generation after generation.
                  </p>
                </div>
              </div>
              <div className="burials-col">
                <div className="burials">
                  <img src={natural_three} alt="" />
                  <h3>A Resting Place That Lasts</h3>
                  <p>
                    Unlike many burial plots in the UK that require renewal
                    after 50 or 100 years, Inspired Meadows offers exclusive
                    999-year "forever plots."
                  </p>
                  <p>
                    Your family’s final resting place remains undisturbed,
                    offering peace of mind for those who come after.
                  </p>
                </div>
              </div>
            </div>
            <div className="family-burials-benefits">
              <Link className="schedule-btn" to="/schedule-a-call">
                Schedule a Call <img src={anglerighticon} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="types-family-main">
        <div className="container">
          <div className="row">
            <div className="type-family-head-col">
              <div className="type-family-head">
                <h2>Choose the Perfect Family Burial Plot </h2>
                {/* <p>
                  Finally, we understand that no two families are alike—and your
                  burial space shouldn’t be, either. That’s why we offer bespoke
                  options to suit your unique needs:
                </p> */}
              </div>
            </div>
            <div className="family-types-col">
              <div className="family-types">
                {/* <div className="family-types-box">
                  <div className="types-details">
                    <h3>Cremation-Only Plots</h3>
                    <p>
                      At Inspired Meadows we specialise in cremation burials,
                      which are a more sustainable and space-conscious option
                      compared to full body burials. Cremation plots can also
                      accommodate multiple urns, making them ideal for family
                      members who wish to share a single, serene resting space.
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureTwo} />
                  </div>
                </div> */}
                <div className="family-types-box">
                  <div className="family-types-img">
                    <img src={NatureSix} />
                  </div>
                  <div className="types-details">
                    <h3>Companion Plots</h3>
                    <p>
                      For couples or close family members who wish to rest side
                      by side, our companion plots provide an intimate,
                      connected space that ensures your journey together
                      continues in peace.
                    </p>
                  </div>
                </div>
                <div className="family-types-box third">
                  <div className="types-details">
                    <h3>Personalised Family Plots</h3>
                    <p>
                      Larger family plots allow multiple generations to share a
                      single space, surrounded by the beauty of native
                      wildflowers and trees. These plots grow with your family,
                      serving as a natural memorial that honours the love, life,
                      and connections that continue beyond time.
                    </p>
                    <Link className="schedule-btn" to="/schedule-a-call">
                      Speak to our Team <img src={anglerighticon} />
                    </Link>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureFour} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="family-why-choose-main">
        <div className="container">
          <div className="row">
            <div className="family-why-head-col">
              <div className="family-why-head">
                <h2>Why Choose Us for Your Family Burial Plot?</h2>
              </div>
            </div>
            {/* <div className="family-full-col">
              <div className="family-full">
                <h3>A New Approach to Planning</h3>
                <p>
                  We believe in transforming the conversation around end-of-life
                  planning. Inspired Meadows offers a gentle, sustainable
                  alternative to traditional burials, making it easier for both
                  individuals and families to embrace a meaningful,
                  forward-thinking approach to their legacy.
                </p>
              </div>
            </div> */}
            <div className="family-half-col">
              <div className="family-half">
                <h3>A New Approach to Planning  </h3>
                <p>
                  We believe in transforming the conversation around end-of-life
                  planning. Inspired Meadows offers a gentle, sustainable
                  alternative to traditional burials, making it easier for both
                  individuals and families to embrace a meaningful,
                  forward-thinking approach to their legacy.
                </p>
              </div>
            </div>
            <div className="family-half-col">
              <div className="family-half">
                <h3>Rooted in Sustainability  </h3>
                <p>
                  Every aspect of Inspired Meadows is designed to preserve and
                  protect the natural world. We believe in the power of nature
                  to provide eternal beauty, and our meadowland is testament to
                  that.
                </p>
                <p>
                  Our burial practices are free from harsh chemicals and
                  non-biodegradable materials, ensuring that your final resting
                  place becomes a part of the earth’s renewal process rather
                  than its depletion.
                </p>
              </div>
            </div>
            <div className="family-half-col">
              <div className="family-half new-family-half">
                <h3>Flexible Payment Plans  </h3>
                <p>
                  We understand that planning for the future can feel
                  overwhelming, which is why we offer flexible, interest-free
                  payment plans. With just a 25% down payment, families can
                  secure their plot and budget in a way that suits them best.
                </p>
              </div>
            </div>
            <div className="family-half-col">
              <div className="family-half new-family-half">
                <h3>Spacious & Thoughtfully Designed</h3>
                <p>
                  Our burial plots are among the most spacious available in the
                  UK. Our meadowland plots provide room to create a peaceful,
                  undisturbed memorial garden that reflects your family’s story.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="cta_new green-cta">
        <NewCta />
      </div> */}

      <div className="credit-section green-cta">
        <div className="container">
          <div className="row">
            <div className="credit-img-col">
              <div className="credit-img">
                <img src={CreditOne} />
              </div>
            </div>
            <div className="credit-content-col">
              <div className="credit-content">
                <h2>Check Your Loan Eligibility</h2>
                <p>
                  Find out if you are approved to apply & get your estimated
                  credit limit.
                </p>
                <p>
                  <b> Funeral Safe</b>, proudly partnering with{" "}
                  <b>Inspired Meadows Ltd</b>.
                </p>
                <a
                  href="https://partners.funeralsafe.co.uk/funeral-directors/inspired-meadows-ltd/"
                  target="blank"
                >
                  Check Your Eligibility
                </a>
              </div>
            </div>
            <div className="credit-img-col">
              <div className="credit-img">
                <img src={CreditTwo} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="new-cta-main">
        <div className="container">
          <div className="new-cta">
            <img src={bgimg} />
            <div className="new-cta-details">
              <h2>Plan Your Family Legacy Today</h2>
              <p>
                A family burial plot at Inspired Meadows is more than a final
                resting place. It’s a lasting tribute, a sanctuary of love, and
                a return to nature. Whether you are planning ahead or making
                arrangements for a loved one, we are here to help you create a
                space of peace, beauty, and connection for generations to come.
              </p>
              <Link className="schedule-btn" to="/schedule-a-call">
                Schedule a Call <img src={anglerighticon} />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Familyburials;
