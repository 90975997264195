import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import arrow_left from "../../assets/images/arrow_left.png";
import kendal_img from "../../assets/images/kendal_img.png";
import kendal_two_img from "../../assets/images/kendal_two.png";
import harmony_first from "../../assets/images/harmony_first.png";
import harmony_two from "../../assets/images/harmony_two.png";
import angle_right from "../../assets/images/angle-right-icon.png";
import angle_icon from "../../assets/images/angle-icon.png";
import kendal_unique from "../../assets/images/kendal_unique.png";
import nature_timeless from "../../assets/images/nature_timeless.png";
import agent from "../../agent";
import peace_img from "../../assets/images/peace_img.png";
import conclusion_img from "../../assets/images/conclusion_img.png";
import phoneIcon from "../../assets/images/Phone-icon.png";
import email_icon from "../../assets/images/email_icon.png";
import location_img from "../../assets/images/Location_details_img.png";
import serene_img from "../../assets/images/serene_img.png";

import { FETCH_LOCATION } from "../../constants/actionTypes";
import MainView from "../Products/MainView";
import SEO from "../SEO/SEO";
import NewCta from "../Home/NewCta";
import CreditOne from "../../assets/images/credit_first.jpg";
import CreditTwo from "../../assets/images/credit_two.jpg";

const mapStateToProps = (state) => ({
  ...state,
  LocationData: state.common.LocationData,
});

const mapDispatchToProps = (dispatch) => ({
  onLoadLocation: async (lid) => {
    try {
      const response = await agent.common.getLocation(lid);
      const data = response; // Assuming the data is within the response object
      dispatch({
        type: FETCH_LOCATION,
        payload: data,
      });
    } catch (error) {
      // Handle error here if needed
    }
  },
});

const Detail = (props) => {
  const { LocationData, onLoadLocation } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [localAmenities, setLocalAmenities] = useState({});
  const [locationAddress, setLocationAddress] = useState({});
  const [surroundingArea, setSurroundingArea] = useState("");
  const [images, setImages] = useState([]);

  let params = useParams();
  const { id } = useParams();
  const location = useLocation();

  const viewMode = location.pathname.includes("/map") ? "map" : "details";
  useEffect(() => {
    if (params) {
      onLoadLocation(params.id);
    }
  }, []);

  useEffect(() => {
    if (LocationData) {
      setName(LocationData?.siteName);
      setDescription(LocationData?.description);
      setLocalAmenities(LocationData?.localAmenities);
      setLocationAddress(LocationData?.locationAddress);
      setSurroundingArea(
        LocationData?.locationAddress &&
          LocationData.locationAddress.surroundingArea
          ? LocationData.locationAddress.surroundingArea
          : null
      );
      setImages(LocationData?.images);
    }
  }, [LocationData]);
  return (
    <Fragment>
      <SEO
        title="Natural Burials in Kendal, UK - Inspired Meadows"
        description="Explore natural burials in Kendal, UK at Inspired Meadows, offering eco-friendly burial options in serene, natural surroundings for a peaceful return to nature."
      />
      <section id="location-details-main">
        <div className="container">
          <div className="row">
            <div className="back-btn-col">
              <div className="back-btn">
                <Link to="/locations">
                  <img src={arrow_left} alt="arrow left icon" />
                  Go back
                </Link>
              </div>
            </div>
            <div className="details-head-col">
              <div className="details-head">
                <div className="head-text">
                  <h2>{name}</h2>
                </div>
                <div className="details-btn">
                  <Link
                    className={viewMode === "details" ? "active" : ""}
                    to={`/location/${params.id}`}
                  >
                    Details view
                  </Link>
                  <Link
                    className={viewMode === "map" ? "active" : ""}
                    to={`/location/${params.id}/map`}
                  >
                    Map view
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {viewMode === "map" ? (
          <MainView viewMode={viewMode} id={id} />
        ) : (
          <>
            <div className="container">
              <div className="row">
                <div className="details-view-col">
                  <div class="details-img-view">
                    <img src={location_img} alt="Location details image" />
                    {/* <p className="p-spacing">{description}</p> */}
                    <p>
                      In the Heart of the Southern Lake District: A Sanctuary at
                      Inspired Meadows Kendal{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <section id="kendal-main">
              <div class="container">
                <div class="row">
                  <div class="kendal-col">
                    <div class="kendal">
                      <img src={kendal_img} alt="" />
                      <p>
                        Nestled within 25 acres of the Southern Lake District's
                        awe-inspiring vistas, Inspired Meadows Kendal emerges as
                        more than just a burial ground; it's a sanctuary where
                        peace and natural beauty converge. This land, revered
                        for its soul-touching, picturesque landscapes, offers
                        more than a final resting place; it promises a journey's
                        graceful conclusion amid tranquil surroundings.
                      </p>
                    </div>
                  </div>
                  <div class="kendal-col">
                    <div class="kendal">
                      <img src={kendal_two_img} alt="" />
                      <p>
                        As we gently reshape this expanse, thousands of
                        carefully selected trees and a myriad of wildflowers
                        flowers will bloom, gradually crafting the meadows into
                        a quintessentially English parkland. This transformation
                        is envisioned with respect and sensitivity, ensuring
                        that every element harmonises with the natural beauty
                        and heritage of Kendal. Each season will paint a new
                        picture, bringing with it a cascade of colours and
                        textures, offering a tranquil, ever-changing backdrop
                        for reflection and remembrance.
                      </p>
                    </div>
                  </div>
                  <div class="kendal-paragraph-col">
                    <div class="kendal-paragraph">
                      <p>
                        In creating this serene parkland, Inspired Meadows
                        Kendal isn't just preserving the essence of the region;
                        it's offering a piece of its soul — a comforting,
                        enduring space where memories can be cherished, and
                        legacies honoured amidst the timeless beauty of the Lake
                        District.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="harmony-main">
              <div class="container">
                <div class="row">
                  <div class="harmony-head-col">
                    <div class="harmony-head">
                      <h1>Natural Burials in Kendal, UK</h1>
                    </div>
                  </div>
                  <div class="pet-burial-col">
                    <div class="pet-burials-find">
                      <div class="pet-burial-right">
                        <img src={harmony_first} alt="" />
                      </div>
                      <div class="pet-burial-left">
                        <p>
                          At Inspired Meadows Kendal, we deeply recognise the
                          profound bond you or your loved ones may share with
                          the natural world, and this consecrated land. It's
                          more than a location; it's a testament to the eternal
                          beauty of life and nature’s cycle. We offer a serene,
                          natural burial within these poetic environs, where
                          each element has been thoughtfully chosen to reflect
                          the tranquillity and majesty of the Lake District.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="pet-burial-col">
                    <div class="pet-burials-find">
                      <div class="pet-burial-left">
                        <p>
                          Our approach in designing the grounds thoughtfully
                          balances the essence of both traditional and natural
                          burial sites. This unique blend allows you the freedom
                          and flexibility to choose a final resting place that
                          truly resonates with your personal beliefs and love
                          for nature. Whether you seek the simplicity of a
                          natural burial or prefer a site that incorporates
                          elements of traditional memorialisation, our landscape
                          provides a dignified, beautiful setting that honours
                          each individual journey.{" "}
                        </p>
                      </div>
                      <div class="pet-burial-right">
                        <img src={harmony_two} alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="kendal-paragraph-col">
                    <div class="kendal-paragraph">
                      <p>
                        In this gentle embrace of nature, amidst whispering
                        trees and soft, wildflower meadows, Inspired Meadows
                        Kendal offers a place not just for rest, but for
                        remembrance — a tranquil space where the legacy of a
                        life well-lived is cherished in every leaf and stone.{" "}
                      </p>
                    </div>
                  </div>
                  <div class="harmony-cta-col">
                    <div class="harmony-cta-main">
                      <div class="cta">
                        <div class="left">
                          <span>Get in touch</span>
                          <p>Please contact us for more information</p>
                        </div>
                        <Link to="/schedule-a-call">
                          Schedule a Call <img src={angle_right} alt="" />
                        </Link>
                      </div>
                      <div class="cta cta-purchase">
                        <div class="left">
                          <span>Create your natural legacy</span>
                          <p>Make your informed choice now</p>
                        </div>
                        <Link to="/products">
                          Purchase Now
                          <img src={angle_icon} alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <div className="cta_new cta_location">
              <NewCta />
            </div> */}

            <div className="credit-section cta_location">
              <div className="container">
                <div className="row">
                  <div className="credit-img-col">
                    <div className="credit-img">
                      <img src={CreditOne} />
                    </div>
                  </div>
                  <div className="credit-content-col">
                    <div className="credit-content">
                      <h2>Check Your Loan Eligibility</h2>
                      <p>
                        Find out if you are approved to apply & get your
                        estimated credit limit.
                      </p>
                      <p>
                        <b> Funeral Safe</b>, proudly partnering with{" "}
                        <b>Inspired Meadows Ltd</b>.
                      </p>
                      <a
                        href="https://partners.funeralsafe.co.uk/funeral-directors/inspired-meadows-ltd/"
                        target="blank"
                      >
                        Check Your Eligibility
                      </a>
                    </div>
                  </div>
                  <div className="credit-img-col">
                    <div className="credit-img">
                      <img src={CreditTwo} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section id="discover-heritage-main">
              <div class="container">
                <div class="row">
                  <div class="discover-heritage-col">
                    <div class="discover-heritage">
                      <div class="discover-heritage-head">
                        <h2>
                          Discover the Heritage and Beauty of Inspired Meadows
                          Kendal{" "}
                        </h2>
                      </div>
                      <div class="celebration-detail-main">
                        <div class="celebration-detail">
                          <p>
                            At Inspired Meadows Kendal, you'll find more than a
                            burial ground; you're stepping into a piece of
                            history. Originating in the 1800s as a burial site
                            for the local Quaker community, our grounds have
                            since evolved, blending historical significance with
                            the stunning scenery of the Lake District.{" "}
                          </p>
                        </div>
                        <div class="celebration-detail">
                          <p>
                            Surrounding you are views of the Howgill Fells and
                            Farleton Knott, landscapes that capture the essence
                            of the region. This natural setting isn't just
                            visually appealing; it's a habitat for diverse
                            wildlife, adding a sense of life and continuity to
                            the peace and calm of the meadows.{" "}
                          </p>
                        </div>
                        <div class="celebration-detail">
                          <p>
                            Inspired Meadows Kendal is a place where the past is
                            respected and the beauty of nature is embraced,
                            providing a thoughtful, dignified choice for a final
                            resting place.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="overview-main" class="pet-burials-main kendal-unique">
              <div class="container">
                <div class="row">
                  <div class="pet-burials-img-col">
                    <div class="overview-img pet-burials-img">
                      <img src={kendal_unique} alt="overview image" />
                    </div>
                  </div>
                  <div class="pet-burials-text-col">
                    <div class="overview-text pet-text">
                      <h2>Kendal: A Unique Choice for Eternal Peace </h2>
                      <p>
                        Selecting Inspired Meadows Kendal means choosing more
                        than a final resting place. It’s about embracing
                        tranquillity and becoming a part of Kendal's rich,
                        natural landscape. This choice ensures a peaceful,
                        beautiful setting that remains a comforting space for
                        families to return to, fostering a deep connection with
                        the area's enchanting surroundings.
                      </p>
                      <p class="py">
                        {" "}
                        Here, amid the gentle embrace of Kendal, every visit to
                        remember and honour lost loved ones becomes a moment of
                        peace, surrounded by the area’s timeless natural beauty.{" "}
                      </p>
                      <Link to="/schedule-a-call">Schedule a Call</Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="pet-burials-main" class="sunrise-main">
              <div class="container">
                <div class="row">
                  <div class="pet-burial-col">
                    <div class="pet-burials-find">
                      <div class="pet-burial-left">
                        <h2>Creating a Timeless Legacy in Nature </h2>
                        <p>
                          At Inspired Meadows Kendal, we offer more than just
                          burial plots; we offer the opportunity to create a
                          personal sanctuary. Our individual plots, each
                          spanning 8 square meters within our walled meadow, are
                          designed for Customisation. Here, you can select your
                          favourite trees and flowers to create a living,
                          breathing memorial garden, a place where memories and
                          nature flourish side by side.
                        </p>
                        <p class="py">
                          For those looking to craft a lasting family legacy,
                          adjoining plots are available. This option is perfect
                          for couples or families who desire to remain together,
                          offering a shared space that future generations can
                          visit, cherish, and find comfort in. These family
                          plots become more than a resting place; they are a
                          testament to unity, love, and the continuity of family
                          through the ages.
                        </p>
                      </div>
                      <div class="pet-burial-right">
                        <img src={nature_timeless} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              id="overview-main"
              class="pet-burials-main kendal-unique peace-main"
            >
              <div class="container">
                <div class="row">
                  <div class="pet-burials-img-col">
                    <div class="overview-img pet-burials-img">
                      <img src={peace_img} alt="overview image" />
                    </div>
                  </div>
                  <div class="pet-burials-text-col">
                    <div class="overview-text pet-text">
                      <h2>Peace of Mind: A Journey Pre-Planned </h2>
                      <p>
                        Making the decision now to secure your final resting
                        place at Inspired Meadows Kendal offers more than just
                        peace of mind; it's an act of love and foresight. By
                        choosing your spot in advance, you lift a significant
                        burden off your family during a difficult time, ensuring
                        your wishes are respected and you are surrounded by the
                        undisturbed beauty of nature.
                      </p>
                      <p class="py">
                        {" "}
                        Opting for a family legacy plot — consisting of 4 or 5
                        adjoining plots — isn’t just a practical choice: it’s a
                        profound gesture of unity. This larger, shared space
                        promises a common resting ground for multiple
                        generations, effectively simplifying end-of-life
                        planning for those who come after you. It’s more than a
                        plot; it’s the assurance that your family will remain
                        interconnected through time, a comforting thought that
                        resonates deeply both for you and your loved ones.
                      </p>
                      <Link to="/products">Purchase Now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              id="overview-main"
              class="pet-burials-main kendal-unique conclusion-main"
            >
              <div class="container">
                <div class="row">
                  <div class="pet-burials-text-col">
                    <div class="overview-text pet-text">
                      <h2>
                        Conclusion: Uniting with Nature at Inspired Meadows
                        Kendal{" "}
                      </h2>
                      <p>
                        Inspired Meadows Kendal is where the serenity of nature
                        and the richness of heritage converge, creating a
                        sanctuary that is both peaceful and poignant. Here,
                        amidst the lush landscapes and timeless vistas, memories
                        are cherished and the spirit of tranquillity reigns.
                      </p>
                      <p class="py">
                        By choosing Inspired Meadows Kendal, you're not just
                        selecting a final resting place; you're embracing a
                        legacy that resonates with the natural beauty and
                        profound peace of the Lake District. Begin your eternal
                        journey with us and let nature's embrace be your lasting
                        legacy.{" "}
                      </p>
                    </div>
                  </div>
                  <div class="pet-burials-img-col">
                    <div class="overview-img pet-burials-img">
                      <img src={conclusion_img} alt="overview image" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="petburial-paragraph" class="location-access-meet">
              <div class="container">
                <div class="row">
                  <div class="paragraph-col">
                    <div class="paragraph">
                      <p>Accessibility Meets Natural Wonder </p>
                      <span>
                        Inspired Meadows Kendal combines easy access with the
                        stunning scenery of the Lake District. Conveniently
                        located just off the A65, the site is not only
                        straightforward to reach but also sits in close
                        proximity to a host of natural wonders. This easy
                        accessibility ensures that visiting your loved ones or
                        simply enjoying the tranquil beauty of the area is
                        always a stress-free and peaceful experience.{" "}
                      </span>
                    </div>
                  </div>
                  <div class="get-info-col">
                    <div class="get-info">
                      <div class="get-head">
                        <div className="get-left">
                          <span>Get in touch</span>
                          <p>Please contact us for more information</p>
                        </div>
                        <Link to="/schedule-a-call">
                          Schedule a Call
                          <img src={angle_right} alt="angle_right" />
                        </Link>
                      </div>
                      <div class="get-contact">
                        <ul>
                          <li>
                            <a href="mailto:info@inspiredmeadows.co.uk">
                              {" "}
                              <img src={email_icon} alt="Email icon" />
                              info@inspiredmeadows.co.uk
                            </a>
                          </li>
                          <li>
                            <a href="https://wa.me/447759123456">
                              {" "}
                              <img src={phoneIcon} alt="Phone image" />
                              01392 576 302
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="serene-main">
              <div class="container">
                <div class="row">
                  <div class="serene-col">
                    <div class="serene">
                      <img src={serene_img} alt="" />
                      <div class="serene-text">
                        <h2>Secure your serene final resting place</h2>
                        <p>
                          Click 'Purchase Now' to reserve your burial plot.
                          Ensure lasting peace for yourself and ease for your
                          loved ones during challenging times. Your eternal
                          sanctuary awaits – reserve it today.
                        </p>
                        <a href="/products">
                          Purchase Now{" "}
                          <img src={angle_right} alt="angle_right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </section>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
